/* IMPORT */
import { connect }       from 'react-redux'
import { publicActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    config:     state.public.digital,
    precreated: state.public.precreated
}),

      allActions = { ...publicActions }

export default connect( mapStateToProps, allActions )
