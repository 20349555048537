import { request } from 'tools'
import * as api    from './api'

export const flushCompanyInfo = request.update(
    'storage.company.info.flush',
    ( n, state ) => ({
        ...state,
        companies: {
            ...state.companies,
            current: null
        }
    })
)

export const getCompanyInfo = request.asyncUpdate(
    'storage.company.info.get',
    id => api.getCompany( id ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return { ...state }
        }

        return {
            ...state,
            companies: {
                ...state.companies,
                current: event.data
            }
        }
    }
)

export const getCompanyDomains = request.asyncUpdate(
    'storage.company.domains',
    id => api.getDomains( id ),
    ( event, state ) => {
        const
            company = event.config.params.company,
            domains = []

        if ( event.status === 200 ) {
            event.data.results.forEach( data => {
                domains.push( `@${data.domain}` )
            })
        }

        return {
            ...state,
            domains: {
                ...state.domains,
                [ company ]: domains
            }
        }
    }
)

export const getCompanyTags = request.asyncUpdate(
    'stoarge.company.tags',
    id => api.getTags( id ),
    ( event, state ) => ({
        ...state,
        wallets: {
            ...state.wallets,
            tags: {
                ...state.wallets.tags,
                [ event.config.params.company ]: event.data
            }
        }
    })
)

export const getCompanyWallets = request.asyncUpdate(
    'storage.company.wallets',
    () => api.getWallets(),
    ( event, state ) => {
        const
            data = event.data.cards,
            { companies } = state.user,
            res = { ...state.wallets.cards }

        companies.forEach( c => {
            const
                id = c.company.id,
                filtered = data.filter( card => card.company_owner && card.company_owner.id === id )

            res[ id ] = res[ id ]
                ? [ ...res[ id ], ...filtered ]
                : filtered
        })

        return {
            ...state,
            wallets: {
                ...state.wallets,
                cards: res
            }
        }
    }
)
