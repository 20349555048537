/* IMPORT */
import { connect }                                    from 'react-redux'
import { authActions, userActions, corporateActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    account:   state.user.account,
    companies: state.user.companies,
    current:   state.user.current,
    user:      state.user.info,
    token:     state.auth.info.token,
    domains:   state.domains,
    corpTags:  state.wallets.tags,
    tags:      state.user.tags
}),

      allActions = {
          ...authActions,
          ...userActions,
          ...corporateActions
      }

export default connect( mapStateToProps, allActions )
