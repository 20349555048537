const m = member => ({
    id:          member.id,
    firstName:   member.first_name,
    lastName:    member.last_name,
    fullName:    `${member.first_name} ${member.last_name}`.trim(),
    phone:       member.phone,
    email:       member.email,
    position:    member.position || '',
    photo:       member.owner_user.avatar,
    companyName: member.company || '',
    companyLogo: member.company_owner.square_logo,
    industry:    null,
    created:     member.created,
    modified:    member.modified,
    username:    member.username || '',
    role:        member.role,
    department:  member.department || ''
})

export default m
