import { request } from 'tools'
import * as api    from './api'

export const getTodos = request.asyncUpdate(
    'storage.todo.reminders.get',
    params => api.getTodos( params ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return { ...state }
        }

        const
            { results, ...rest } = event.data

        return {
            ...state,
            todo: {
                ...state.todo,
                list: {
                    ...rest,
                    data: results
                }
            }
        }
    }
)
