
import { Skeleton } from 'antd'

import { Icons, Image } from 'components'

import './contact-card.scss'

const ContactCard = ({ skeleton, file, name, position, company, photo, logo, favourite, todo }) => (
    <div className="contact-card">
        {
            file || ( !photo && !logo )
                ? (
                        <div className="figure">
                            <Image
                                className = "only-card"
                                fallback = '//tabee.app/files/placeholder.jpg'
                                src = { file }
                                alt = ""
                                width = "112"
                                height = "64"
                            />
                        </div>
                    )
                : (
                        <div className="figure">
                            <Image
                                className = "photo"
                                fallback = '//tabee.app/files/placeholder_avatar@2x.jpg'
                                src = { photo }
                                alt = { name }
                                width = "64"
                                height = "64"
                            />
                            <Image
                                className = "logo"
                                fallback = {'https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png'}
                                src = { logo }
                                alt = { company }
                                width = "128"
                                height = "128"
                            />
                        </div>
                    )
        }
        {
            skeleton
                ? (
                        <div className="details" style={{ width: 200 }}>
                            <Skeleton paragraph={{ rows: 2 }} active size="small" style={{ width: 200 }} />
                        </div>
                    )
                : (
                        <div className="details">
                            <div className="item name" title={name}>
                                <span className="card-icons">
                                    {
                                        favourite && (
                                            <span className="fav-icon">
                                                <Icons.Fav />
                                            </span>
                                        )
                                    }
                                    {
                                        todo && (
                                            <span className="todo-icon">
                                                <Icons.TodoIcon />
                                            </span>
                                        )
                                    }
                                </span>
                                { name }
                            </div>
                            <div className="item extra" title={position}>
                                { position }
                            </div>
                            <div className="item extra" title={company}>
                                { company }
                            </div>
                        </div>
                    )
        }
    </div>
)

export default ContactCard
