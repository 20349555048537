import React                               from 'react'
import PropTypes                           from 'prop-types'
import { Checkbox, Radio, Dropdown, Menu } from 'antd'

import { Option, Icons } from 'components'
import { t, strings }    from 'tools'
import config            from 'config'

import './unified-sorter.scss'

const
    { OptionSection, OptionName } = Option

class UnifiedSorter extends React.Component {

    static propTypes = {
        sort:     PropTypes.string,
        onChange: PropTypes.func
    }

    set = item => e => {
        this.prevent( e )
        this.props.onChange( item.id )
    }

    checked = item => this.props.sort === item.id

    prevent = e => e.stopPropagation()

    item = item =>
        (
            <div className="option-item" key={item.id} onClick={ this.set( item ) }>
                <OptionName>{ t( `columns.columns${strings.toCamelCase( item.name )}` ) }</OptionName>
                {
                    item.type !== 'radio'
                        ? (
                                <Checkbox
                                    className = "small-size"
                                    value = { item.id }
                                    checked = { this.checked( item ) }

                                    onChange={ this.set( item ) }
                                />
                            )
                        : (
                                <Radio
                                    size = "small"
                                    value = { item.id }
                                    checked = { this.checked( item ) }

                                    onChange={ this.set( item ) }
                                />
                            )
                }
            </div>
        )

    group = option =>
        (
            <OptionSection key={option.id} name={option.name} onClick={this.prevent}>
                <h5>{ t( 'global.sortBy' ) }</h5>
                {
                    option.children.items.map( this.item )
                }
            </OptionSection>
        )

    render () {
        const
            filters = (
                <Menu>
                    { config.ui.sortConfig.map( this.group ) }
                </Menu>
            )

        return (
            <Dropdown
                overlay = { filters }
                trigger = "click"
                overlayClassName = "wallets-columns-dropdown unified-sorter-dropdown"
                onClick = { this.prevent }
            >
                <div className="wallets-columns-filters unified-sorter">
                    <Icons.Sorter />
                </div>
            </Dropdown>
        )
    }
}

export default UnifiedSorter
