/* eslint-disable no-case-declarations */
import { useEffect }             from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { request } from 'tools'

import config from 'config'

const AuthCheck = () => {
    const
        { mode } = useParams(),
        history = useHistory(),

        parseSource = raw => {
            if ( !raw ) { return {} }

            const
                parsed = JSON.parse( atob( raw ))

            return parsed
        }

    useEffect(() => {
        const
            query = new URLSearchParams( history.location.search ),
            returnURL = query.get( 'return_url' ),
            source = parseSource( query.get( 'source' ))

        switch ( mode ) {
            case 'sso':
                const
                    token = query.get( 'hash' ),
                    account = query.get( 'user' )

                request.storage.set( 'token', token )
                request.storage.set( 'user_id', account )
                request.storage.set( 'account', account )

                window.location.href = source.return_url || returnURL || '/'
                break

            case '2fa':
                window.location = `${config.sso.url}/auth/${mode}${window.location.search}`
                break

            default:
                history.push( source.return_url || returnURL || '/' )
        }
    }, [])

    return <></>
}

export default AuthCheck
