import React              from 'react'
import PropTypes          from 'prop-types'
import { Button, Drawer } from 'antd'

import { Option, Icons, SelectedOptionsGroup } from 'components'
import { strings, generate, t }                from 'tools'

import './selected-wallets.scss'

const
    { OptionSummary } = Option

class SelectedWallets extends React.Component {

    static propTypes = {
        show: PropTypes.bool,
        open: PropTypes.bool,
        data: PropTypes.array,
        tags: PropTypes.array,

        onAssign: PropTypes.func,
        onCreate: PropTypes.func,
        onClose:  PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            sub:      null,
            toAssign: []
        }

        this.set = generate.set( this )
    }

    apply = ( opt, value ) => {
        switch ( opt.id ) {
            case 'tags':
                this.set.toAssign( value )
                break
            default:
                console.log( '?', opt, value )
        }
    }

    assign = () => {
        this.props.onAssign( this.state.toAssign )
        this.set.state({
            toAssign: [],
            sub:      null
        })
    }

    render () {
        const
            { open, data, tags, show } = this.props,
            { sub, toAssign } = this.state,
            config = {
                id:        'tags',
                name:      null,
                type:      'tags',
                icon:      <Icons.Tag />,
                all:       t( 'wallets.assignTags' ),
                counter:   t( 'counters.countersTag' ),
                selection: true,
                sub:       true,
                search:    true,
                items:     ( tags || []).filter( t => !t.is_deleted ).sort(( a, b ) => a.name.localeCompare( b.name ))
            }

        return (
            <Drawer
                title = ""
                placement = "right"
                closable = { false }
                getContainer = { false }
                className = "selected-wallets"

                visible = { open }
                style = {{ position: 'absolute' }}
                width = { 500 }

                onClose = { this.props.onClose }
            >
                <div className="drawer-form">
                    <header className="form-header">
                        <h3>{ strings.encount( data.length, t( 'counters.countersContact' )) } selected</h3>
                        {
                            this.props.onClose && (
                                <Button onClick={this.props.onClose}>
                                    Close
                                </Button>
                            )
                        }
                    </header>
                    <section className="form-content">
                        {
                            sub
                                ? (
                                        <div className="selected-sub">
                                            <SelectedOptionsGroup
                                                option = {{ ...sub, name: sub.all }}
                                                filter = { toAssign }

                                                onSelect = { this.delayApply }
                                                onAccept = { this.apply }
                                                onClose = { this.set.delayed( 'sub' )( null ) }
                                            />
                                            <footer className="selected-sub-footer">
                                                <Button
                                                    type = "primary"
                                                    className = "centered"
                                                    disabled = { toAssign.length < 1 }

                                                    onClick = { this.assign }
                                                >
                                                    {
                                                        t(
                                                            'wallets.assignTagsTo',
                                                            {
                                                                tags: toAssign.length
                                                                    ? strings.encount(
                                                                        toAssign.length,
                                                                        t( 'counters.countersTag' )
                                                                    )
                                                                    : t( 'global.countersTags' ),
                                                                wallets: strings.encount(
                                                                    data.length,
                                                                    t( 'counters.countersContact' )
                                                                )
                                                            }
                                                        )
                                                    }
                                                </Button>
                                            </footer>
                                        </div>
                                    )
                                : (
                                        <ul className="drawer-actions">
                                            {
                                                show && (
                                                    <li>
                                                        <OptionSummary
                                                            key = "tags"
                                                            option = { config }
                                                            filter = { null }

                                                            onSelect = { this.set.delayed( 'sub' ) }
                                                        />
                                                    </li>
                                                )
                                            }
                                            <li>
                                                <Icons.Unlinked />
                                                <h5>{ t( 'wallets.createPrivateList' ) }</h5>
                                                <Button type="primary" onClick={this.props.onCreate}>
                                                    { t( 'sharing.createList' ) }
                                                </Button>
                                            </li>
                                            <li>
                                                <Icons.Linked />
                                                <h5>{ t( 'wallets.createPublicList' ) }</h5>
                                                <Button type="primary" onClick={this.props.onShare}>
                                                    { t( 'sharing.shareContacts' ) }
                                                </Button>
                                            </li>
                                            {
                                                show && (
                                                    <li>
                                                        <Icons.Merge />
                                                        <h5>{ t( 'wallets.mergeContacts' ) }</h5>
                                                        <Button type="primary" onClick={this.props.onMerge}>
                                                            { t( 'global.merge' ) }
                                                        </Button>
                                                    </li>
                                                )
                                            }
                                            {
                                                show && (
                                                    <li>
                                                        <Icons.Remove />
                                                        <h5>{ t( 'wallets.deleteContacts' ) }</h5>
                                                        <Button type="primary" danger onClick={this.props.onRemove}>
                                                            { t( 'global.delete' ) }
                                                        </Button>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    )
                        }
                    </section>
                </div>
            </Drawer>
        )
    }
}

export default SelectedWallets
