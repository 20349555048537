import browserSignature from 'browser-signature'
import moment           from 'moment'

import config            from 'config'
import { request, copy } from 'tools'
import * as api          from './api'

export const reloadCard = request.update(
    'storage.public.card.reload',
    ( data, state ) => ({
        ...state,
        public: {
            ...state.public,
            reloadContacts: data
        }
    })
)

export const flushVerifyDomain = request.update(
    'storage.public.company.info.flush',
    ( _, state ) => ({
        ...state,
        public: {
            ...state.public,
            domain: {}
        }
    })
)

export const flushSearchCard = request.update(
    'storage.public.card.search.flush',
    ( _, state ) => ({
        ...state,
        public: {
            ...state.public,
            search: null
        }
    })
)

export const searchCard = request.asyncUpdate(
    'storage.public.card.search',
    mail => api.searchCard( mail?.toLowerCase()),
    ( event, state ) => {
        if ( event.status > 299 ) {
            return {
                ...state,
                public: {
                    ...state.public,
                    search: {
                        error:  true,
                        data:   event.data,
                        length: 0
                    }
                }
            }
        }

        return {
            ...state,
            public: {
                ...state.public,
                search: event.data
            }
        }
    }
)

export const getActivated = request.asyncUpdate(
    'storage.public.activated.get',
    id => api.getPublicCard( id ),
    ( event, state ) => {
        if ( event.status >= 300 ) {
            if ( event.data?.detail === 'Invalid token.' ) {
                request.storage.remove( 'token' )
                window.location.reload()
            } else {
                return {
                    ...state,
                    public: {
                        ...state.public,
                        error: {
                            status: event.status,
                            data:   event.data
                        }
                    }
                }
            }
        }

        return {
            ...state,
            public: {
                ...state.public,
                activated:      event.data,
                reloadContacts: 'activated'
            }
        }
    }
)

export const getPublicCard = request.asyncUpdate(
    'storage.public.card.get',
    id => api.getPublicCard( id ),
    ( event, state ) => {
        if ( event.status >= 300 ) {
            if ( event.data?.detail === 'Invalid token.' ) {
                request.storage.remove( 'token' )
                window.location.reload()
            } else {
                return {
                    ...state,
                    public: {
                        ...state.public,
                        error: {
                            status: event.status,
                            data:   event.data
                        }
                    }
                }
            }
        }

        const
            card = { ...event.data }

        ;[ 'first_name', 'last_name', 'position' ].forEach( key => {
            card[ key ] = card[ key ] || ''
        })

        return {
            ...state,
            public: {
                ...state.public,
                card
            }
        }
    }
)

export const verifyDomain = request.asyncUpdate(
    'storage.public.company.info.get',
    domain => api.verifyDomain( domain?.toLowerCase()),
    ( event, state ) => {
        const
            res = ( event.data?.results )
                ? {
                        ...( event.data.results[ 0 ]?.company || {}),
                        is_invalid: event.data.results[ 0 ]?.is_invalid || event.data.results[ 0 ]?.is_restricted,
                        is_public:  event.data.results[ 0 ]?.is_public,
                        is_empty:   event.data.results.length === 0,
                        domain:     event.data.results[ 0 ]?.domain
                    }
                : null

        return {
            ...state,
            public: {
                ...state.public,
                domain: res
            }
        }
    }
)

export const clearStepsErrors = request.update(
    'storage.public.steps.clear.errors',
    ( _, state ) => {
        const
            states = state.public.steps.status

        Object.keys( states ).forEach( key => {
            ( states[ key ] === 'error' ) && ( states[ key ] = false )
        })

        return {
            ...state,
            public: {
                ...state.public,
                steps: {
                    ...state.public.steps,
                    status: states
                }
            }
        }
    }
)

export const migrateCard = request.asyncUpdate(
    'storage.public.migrate',
    body => api.migrateCard( body ),
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            migrate: event.status < 300 ? event.data : false
        }
    })
)

export const activateUser = request.asyncUpdate(
    'storage.public.activate',
    body => api.activateUser( body ),
    ( event, state ) => {
        if ( event.status < 300 ) {
            sessionStorage.setItem( `${config.ui.prefix}_token`, event.data.token )
            sessionStorage.setItem( `${config.ui.prefix}_user_id`, event.data.user.id )
            sessionStorage.setItem( `${config.ui.prefix}_account`, event.data.user.id )

            return {
                ...state,
                public: {
                    ...state.public,
                    activate: event.data
                },
                auth: {
                    ...state.auth,
                    info:    event.data,
                    logged:  event.status < 300,
                    lastTry: moment(),
                    msg:     ''
                },
                user: {
                    ...state.user,
                    info:  event.data.user,
                    cards: [ event.data.card ]
                }
            }
        } else {
            return {
                ...state,
                public: {
                    ...state.public,
                    activate: null
                }
            }
        }
    }
)

export const sharePublicContacts = request.asyncUpdate(
    'storage.public.share.contacts.public',
    body => api.exchangePublicContacts( body ),
    ( _, state ) => ({
        ...state,
        public: {
            ...state.public,
            shared: true
        }
    })
)

export const shareContacts = request.asyncUpdate(
    'storage.public.share.contacts',
    body => api.exchangeContacts( body ),
    ( _, state ) => ({
        ...state,
        public: {
            ...state.public,
            shared: true
        }
    })
)

export const updateAvatar = request.asyncUpdate(
    'storage.public.avatar.update',
    data => api.updateAvatar( data ),
    ( event, state ) => {
        if ( event.status < 300 ) {
            return {
                ...state,
                public: {
                    ...state.public,
                    reloadContacts: event
                }
            }
        } else {
            return { ...state }
        }
    }
)

export const updateClaimCorp = request.asyncUpdate(
    'storage.public.claim_corporate.update',
    state => api.updateClaimCorp({ is_claim_corporate: state }),
    ( event, state ) => {
        if ( event.status < 300 ) {
            return {
                ...state,
                public: {
                    ...state.public,
                    reloadContacts: event
                }
            }
        } else {
            return { ...state }
        }
    }
)

export const updateContact = request.asyncUpdate(
    'storage.public.contact.update',
    data => api.updateContact( data ),
    ( event, state ) => {
        if ( event.status < 300 ) {
            const
                contacts = state.public.activated
                    ? copy.array( state.public.activated.shared_contacts )
                    : copy.array( state.public.card.shared_contacts ),
                index = contacts.findIndex( c => c.id === event.data.id )

            contacts[ index ] = event.data

            return state.public.activated
                ? {
                        ...state,
                        public: {
                            ...state.public,
                            activated: {
                                ...state.public.activated,
                                shared_contacts: contacts
                            },
                            reloadContacts: event
                        }
                    }
                : {
                        ...state,
                        public: {
                            ...state.public,
                            card: {
                                ...state.public.card,
                                shared_contacts: contacts
                            },
                            reloadContacts: event
                        }
                    }

        } else {
            return { ...state }
        }
    }
)

export const createContact = request.asyncUpdate(
    'storage.public.contact.create',
    data => api.addContact( data ),
    ( event, state ) => {
        if ( event.status < 300 ) {
            const
                contacts = state.public.activated
                    ? copy.array( state.public.activated.shared_contacts )
                    : copy.array( state.public.card.shared_contacts )

            contacts.push( event.data )

            return state.public.activated
                ? {
                        ...state,
                        public: {
                            ...state.public,
                            needSave:  event,
                            activated: {
                                ...state.public.activated,
                                shared_contacts: contacts
                            }
                        }
                    }
                : {
                        ...state,
                        public: {
                            ...state.public,
                            needSave: event,
                            card:     {
                                ...state.public.card,
                                shared_contacts: contacts
                            }
                        }
                    }

        } else {
            return { ...state }
        }
    }
)

export const updateCard = request.asyncUpdate(
    'storage.public.contacts.card.update',
    data => api.updateCard( data ),
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            cardSaved: event,
            needSave:  false
        }
    })
)

export const updateCardReload = request.asyncUpdate(
    'storage.public.contacts.card.updat.reload',
    data => api.updateCard( data ),
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            reloadContacts: event
        }
    })
)

export const precreateCard = request.asyncUpdate(
    'storage.public.contact.precreate',
    data => {
        const
            uuid = window._adjust_web_uuid

        sessionStorage.removeItem( `${config.ui.prefix}_token` )
        sessionStorage.removeItem( `${config.ui.prefix}_user_id` )
        sessionStorage.removeItem( `${config.ui.prefix}_account` )

        return api.registerDevice({
            'token': '0',
            'hvid':  uuid ?? null,

            'appv':           data?.appv || '1.1',
            'company_design': data?.company_design || '',
            'platform':       data?.platform || '3',
            'project':        data?.project || '73e9b050-3259-11ec-8038-6a13bef2453b',
            'type':           data?.type || 'id-card'
        })
    },
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            precreated: event.data
        }
    })
)

const
    inccontact = ( data, keys, is_validated ) => {
        const
            res = []

        keys.forEach( key => {
            if ( data[ key ]) {
                switch ( key ) {
                    case 'email':
                        res.push({
                            'contact': data.email?.toLowerCase(),
                            is_validated,
                            'type':    'email'
                        })
                        break

                    case 'phone':
                        res.push({
                            'contact': data.phone,
                            'type':    'phone'
                        })
                        break

                    case 'personal_email':
                        res.push({
                            'contact': data.personal_email,
                            'type':    'email'
                        })
                        break

                    default:
                        res.push({
                            contact: data[ key ],
                            'type':  'other'
                        })
                }
            }
        })

        return res
    }

export const oneRegForAll = request.asyncUpdate(
    'storage.public.onereg',
    data => api.oneRegForAll({
        project:               '541ff58e-7d11-11eb-8031-6a13bef2453b',
        is_sending_activation: true,
        card_type:             'identity',
        username:              data.email.replace( /[^0-9a-zA-Z]+/g, '' ).toLowerCase(),
        language:              'en',
        first_name:            data.first_name,
        last_name:             data.last_name,
        position:              data.position,
        company:               data.company_info?.name || data.company,
        company_domain:        data.company_info?.domain || null,
        device:                [ {
            platform: '3',
            token:    '0',
            appv:     `iNqxF-${moment().unix()}`
        } ],
        card_contacts:   inccontact( data, [ 'phone', 'email' ]),
        shared_contacts: inccontact( data, [ 'phone', 'personal_email' ]),
        other_contacts:  [],
        link:            [ {
            recipient:   data.target_id,
            source_type: 'public'
        } ]
    }),
    ( event, state ) => {
        const
            res = { ...state },
            status = event.status < 400

        res.auth = {
            ...res.auth,
            info:    event.data?.user,
            logged:  status,
            lastTry: moment()
        }

        res.user = {
            ...state.user,
            info:        event.data?.user,
            account:     event.data?.user?.id,
            justCreated: true
        }

        res.public.shared = true

        request.storage.set( 'user_id', event.data?.user?.id )
        ;( event.data.token ) && ( request.storage.set( 'token', event.data.token ))

        return {
            ...res,
            public: {
                ...res.public,
                steps: {
                    current: res.public.steps.order.length,
                    done:    true,
                    status:  {
                        device:   true,
                        base:     true,
                        email:    true,
                        contacts: true,
                        phone:    true,
                        final:    true,
                        exchange: true,
                    },
                    data: {
                        device:   event.data,
                        base:     event.data,
                        email:    event.data,
                        contacts: event.data,
                        phone:    event.data,
                        final:    event.data,
                        exchange: event.data,
                    }
                }
            }
        }
    }
)

export const stepHandlers = request.asyncUpdate(
    'storage.public.steps',
    ({ key, owner_id, target_id, phone_id, email_id, ...data }) => {
        const
            fingerprint = browserSignature(),
            handlers = {
                device: () => api.registerDevice({
                    'token':     '0',
                    'platform':  '3',
                    'hvid':      fingerprint,
                    'device_id': fingerprint,
                    'project':   '541ff58e-7d11-11eb-8031-6a13bef2453b',
                    'appv':      '1'
                }),
                base: () => api.basicRegistration({
                    'email':    data.email?.toLowerCase(),
                    'username': data.email?.replace( /[^0-9A-Za-z]+/g, '' ).toLowerCase()
                }),
                email: () => api.addContact({
                    'contact': data.personal_email?.toLowerCase(),
                    'type':    'email'
                }),
                contacts: () => api.getAddedMail( data.id ),
                phone:    () => api.addContact({
                    'contact': data.phone,
                    'type':    'phone'
                }),
                final: () => api.finalRegistration({
                    'language':      'en',
                    'first_name':    data.first_name,
                    'last_name':     data.last_name,
                    'position':      data.position,
                    'company':       data.company_info?.name || data.company,
                    'company_owner': data.company_info?.id,
                    'phone':         phone_id,
                    'email':         email_id
                }),
                exchange: () => api.exchangeContacts({
                    'owner_card':  owner_id,
                    'recipient':   target_id,
                    'is_send':     true,
                    'is_hold':     false,
                    'source_type': 'v2',
                })
            }

        return handlers[ key ]( data )
    },
    ( event, state ) => {
        const
            step = state.public.steps.current,
            key = state.public.steps.order[ step ],
            status = event.status < 300,
            res = { ...state }

        if ( key === 'device' ) {
            res.auth = {
                ...res.auth,
                info:    event.data,
                logged:  status,
                lastTry: moment()
            }
        }

        if ( key === 'exchange' ) {
            res.user = {
                ...state.user,
                info:        state.public.steps.data.final.user,
                account:     state.public.steps.data.final.user.id,
                justCreated: true
            }

            res.public.shared = true

            request.storage.set( 'user_id', state.public.steps.data.final.user.id )
        }

        ( event.data.token ) && ( request.storage.set( 'token', event.data.token ))

        return {
            ...res,
            public: {
                ...res.public,
                steps: {
                    ...res.public.steps,
                    current: status ? step + 1 : step,
                    done:    status && step + 1 >= res.public.steps.order.length,
                    status:  {
                        ...res.public.steps.status,
                        [ key ]: status ? true : 'error'
                    },
                    data: {
                        ...res.public.steps.data,
                        [ key ]: event.data
                    }
                }
            }
        }
    }
)

export const getProjectConfig = request.asyncUpdate(
    'storage.public.contact.types',
    id => api.getProjectConfig( id ),
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            socials:    event.data?.system_json?.enabled_auth,
            types:      event.data?.system_json?.enabled_socials,
            digital:    event.data?.system_json?.digital_wallet,
            onboarding: event.data?.system_json?.onboarding?.full
                ? event.data.system_json.onboarding.full
                : event.data?.system_json?.onboarding
        }
    })
)

export const getCustomDesigns = request.asyncUpdate(
    'storage.public.designs.custom',
    () => api.getCustomDesigns(),
    ( event, state ) => ({
        ...state,
        public: {
            ...state.public,
            customDesigns: event.data?.results
        }
    })
)
