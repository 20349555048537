/* IMPORT */
import { connect }                  from 'react-redux'
import { userActions, authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    cards:     state.wallets.cards,
    user:      state.user.info,
    companies: state.user.companies,
    account:   state.user.account,
    current:   state.user.current,
    domains:   state.domains
}),

      allActions = {
          ...userActions,
          ...authActions
      }

export default connect( mapStateToProps, allActions )
