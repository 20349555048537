import React from 'react'

import { AdminPanel, TeamContent, TeamHeader } from 'components'
import { generate, normalize, hash }           from 'tools'

import connector from './connector'
import './team.scss'

class Team extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            open:     false,
            invite:   false,
            selected: null,
            search:   ''
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { cards, match, account } = this.props,
            { selected } = this.state

        if ( cards !== prevProps.cards && cards && cards[ account ] && match.params.id ) {
            const
                found = cards[ account ].find( card => card.id === match.params.id )

            ;( found ) && ( this.set.selected( found ))
        }

        if ( selected !== prevState.selected ) {
            ( selected )
                ? hash.router.replace( `/corporate/team/${selected.id}` )
                : hash.router.replace( '/corporate/team' )
            ;( !!selected ) && ( this.set.open( true ))
        }
    }

    search = e => this.set.search( e.target.value )

    reset = () =>
        this.set.state({ search: '' })

    close = () => {
        this.set.state({
            open:   false,
            invite: false
        })

        hash.router.replace( '/corporate/team' )

        setTimeout(() => {
            this.set.state({ selected: null })
        }, 300 )
    }

    applySearch = ( cards, search ) =>
        cards.filter( raw => {
            const
                s = search.toLowerCase(),
                card = normalize.member( raw )

            return (
                card.fullName.toLowerCase().indexOf( s ) > -1 ||
                card.position.toLowerCase().indexOf( s ) > -1 ||
                card.username.toLowerCase().indexOf( s ) > -1 ||
                card.department.toLowerCase().indexOf( s ) > -1
            )
        })

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { current, account, cards, user, companies, match, domains } = this.props,
            { search, open, invite, selected } = this.state,
            data = cards && cards[ account ] ? cards[ account ] : null,
            filtered = data && search
                ? this.applySearch( data, search )
                : data

        return (
            <React.Fragment>

                <AdminPanel
                    account = { current }
                    user = { user }
                    companies = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard team-dashboard">
                    <TeamHeader
                        account = { current }
                        loading = { !data }
                        search = { search }

                        total = { data ? data.length : null }
                        hidden = { filtered && filtered.length > 0 ? data.length - filtered.length : null }

                        onTogglePanel = { this.panelToggle }
                        onSearch = { this.search }
                    />
                    <div className="admin-dashboard-content">
                        <TeamContent
                            open = { open }
                            invite = { invite }
                            search = { !!search }

                            cards = { data ? filtered : null }
                            selected = { selected }
                            domains = { domains[ account ] || [] }

                            onEdit = { this.set.delayed( 'selected' ) }
                            onInvite = { this.set.toggle( 'invite' ) }
                            onClose = { this.close }
                            onReset = { this.reset }
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connector( Team )
