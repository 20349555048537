import React     from 'react'
import moment    from 'moment'
import { Modal } from 'antd'

import { AdminPanel, ListsHeader, ListsContent, SharingModal } from 'components'
import { generate, hash, sorter }                              from 'tools'
import config                                                  from 'config'

import connector from './connector'
import './lists.scss'

const
    page = 100

class Lists extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            open:    false,
            catched: false,

            search: '',
            list:   null,

            remove:   false,
            toRemove: null
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { lists, match } = this.props

        ;( !lists.data ) && ( this.load())
        ;( match.params.id === 'draft' || match.params.id === 'null' ) && ( this.create())
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { lists, user, match, history } = this.props,
            { catched, list } = this.state

        if ( lists && user && lists !== prevProps.lists ) {
            ( !!lists.next ) && ( this.loadMore())
            ;( match.params.id && !catched ) && ( this.select( match.params.id ))
        }

        if ( list !== prevState.list ) {
            ( list && list.id !== 'draft' )
                ? history.push( `/sharing/${list.id}/edit` )
                : hash.router.replace( '/sharing' )
        }
    }

    load = () => {
        this.props.getSharingLists()
    }

    loadMore = () => this.props.appendSharingLists({ limit: page, before_id: this.props.personal.latest })
    search = e => this.set.search( e.target.value )

    applySearch = ( lists, search ) =>
        lists.filter( list => list.title && list.title.toLowerCase().indexOf( search.toLowerCase()) > -1 )

    create = () => {
        const
            raw = sessionStorage.getItem( `${config.ui.prefix}_list_draft` )

        if ( !raw ) {
            this.edit({
                id:           'draft',
                title:        '',
                events:       [],
                tags:         [],
                date_disable: null,
                is_private:   true,
                is_fixed:     false,
                created:      moment().unix(),
                owner_user:   this.props.user.id
            })()
        } else {
            const
                data = JSON.parse( raw )

            this.edit({
                ...data,
                id:         'draft',
                created:    moment().unix(),
                owner_user: this.props.user.id
            })()

            sessionStorage.removeItem( `${config.ui.prefix}_list_draft` )
        }
    }

    select = id => {
        const
            { lists } = this.props,
            found = lists.data.find( list => list.id === id )

        ;( found ) && ( this.edit( found )())
    }

    open = item => () => this.props.history.push( `/sharing/${item.id}` )

    askRemove = row => () =>
        this.set.state({
            toRemove: row,
            remove:   true
        })

    confirmRemove = () => {
        this.props.setRemoving( this.state.toRemove.id )
        this.props.removeSharing( this.state.toRemove.id )
        this.cancelRemove()
    }

    cancelRemove = () => {
        this.set.remove( false )
        setTimeout(() => this.set.toRemove( null ), 300 )
    }

    edit = row => () =>
        this.set.state({
            open:    true,
            catched: true,
            list:    row
        })

    close = () => {
        this.set.open( false )
        setTimeout(() => this.set.list( null ), 300 )
    }

    make = data => {
        ( data.is_fixed ) && ( delete data.active_reminders )
        ;( data.id === null ) && ( delete data.id )

        delete data.name
        delete data.type
        delete data.filter
        delete data.expire
        delete data._date_disable
        delete data.activity_threshold
        delete data.export_url

        this.props.createSharing( data )
        this.close()
    }

    update = data => {
        this.props.updateSharing( data )
        this.close()
    }

    events = () => {
        const
            { wallets } = this.props

        let
            res = []

        if ( !wallets.links ) { return res }

        wallets.links.forEach( link => res.push( link.event ))

        return res.filter( item => !!item && ( res.find( i => !!i && i.name === item.name ) === item ))
    }

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { current, user, companies, match, lists, tags } = this.props,
            { search, open, list, remove, toRemove } = this.state,
            events = this.events(),
            filtered = lists.data && search
                ? this.applySearch( lists.data.reverse(), search )
                : (
                        lists.data
                            ? lists.data.reverse()
                            : lists.data
                    )

        return (
            <React.Fragment>

                <AdminPanel
                    account = { current }
                    user = { user }
                    companies = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onExpand = { this.closeDrawer }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard sharing-lists-dashboard">
                    <ListsHeader
                        account = { current }
                        loading = { !lists.data }
                        search = { search }

                        total = { lists.data ? lists.data.length : null }
                        hidden = { filtered && filtered.length > 0 ? lists.data.length - filtered.length : null }

                        onTogglePanel = { this.panelToggle }
                        onSearch = { this.search }
                    />
                    <div className="admin-dashboard-content">
                        <ListsContent
                            open = { open }
                            search = { !!search }

                            lists = { lists.data ? filtered.sort( sorter.date( 'created' )) : null }

                            onRow = { this.open }
                            onAdd = { this.create }
                            onEdit = { this.edit }
                            onRemove = { this.askRemove }
                            onClose = { this.close }
                        />

                        <SharingModal
                            open = { open }
                            list = { list }

                            tags = { tags }
                            events = { events }

                            onAdd = { this.make }
                            onClose = { this.close }
                        />

                        <Modal
                            title = "Remove list"
                            visible = { remove }
                            className = "remove-modal"

                            okText = "Remove"
                            okButtonProps = {{ danger: true }}
                            onOk = { this.confirmRemove }

                            onCancel = { this.cancelRemove }
                            cancelButtonProps = {{ type: 'link' }}
                            cancelText = "Cancel"
                        >
                            Are you sure to remove { toRemove && toRemove.title ? `"${toRemove.title}"` : 'this list' }?
                        </Modal>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default connector( Lists )
