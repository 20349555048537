
import i18next          from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment           from 'moment'

/* moment */
import 'moment/locale/zh-cn'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/ru'
import 'moment/locale/tr'

/* antd */
import _cn from 'antd/es/date-picker/locale/zh_CN'
import _de from 'antd/es/date-picker/locale/de_DE'
import _en from 'antd/es/date-picker/locale/en_GB'
import _es from 'antd/es/date-picker/locale/es_ES'
import _fr from 'antd/es/date-picker/locale/fr_FR'
import _nl from 'antd/es/date-picker/locale/nl_NL'
import _ru from 'antd/es/date-picker/locale/ru_RU'
import _tr from 'antd/es/date-picker/locale/tr_TR'

/* APP */
import cn from 'i18n/cn.json'
import de from 'i18n/de.json'
import en from 'i18n/en.json'
import es from 'i18n/es.json'
import fr from 'i18n/fr.json'
import nl from 'i18n/nl.json'
import ru from 'i18n/ru.json'
import tr from 'i18n/tr.json'

i18next.use( LanguageDetector ).init({
    interpolation: { escapeValue: false },
    fallbackLng:   'en',
    defaultNS:     'main',
    resources:     {
        cn: { main: cn },
        de: { main: de },
        en: { main: en },
        es: { main: es },
        fr: { main: fr },
        nl: { main: nl },
        ru: { main: ru },
        tr: { main: tr },
    },
})

moment.locale( i18next.language )
i18next.on( 'languageChanged', lang => moment.locale( lang ))

const t = ( a, b, c ) => i18next.t( a, b, c ).split( '&nbsp;' ).reduce(( res, current ) => res ? <>{ res }&nbsp;{ current }</> : current, null ),

      dateLocales = {
          cn: _cn,
          de: _de,
          en: _en,
          es: _es,
          fr: _fr,
          nl: _nl,
          ru: _ru,
          tr: _tr,
      }

export { i18next, dateLocales }
export default t
