

import { UserPhoto } from 'components'
import { t }         from 'tools'

import './user-item.scss'

const UserItem = ({ name, desc, tagged, src }) => (
    <div className="user-item">
        <span className="photo">
            <UserPhoto
                name = { name }
                src = { src }
                size = "small"
            />
        </span>
        <span className="name" title={name}>
            <strong>{name}</strong>
            {
                desc && (
                    <span className="desc">
                        ({ desc })
                    </span>
                )
            }
        </span>
        {
            tagged && (
                <span className={`tagged type-${tagged}`}>
                    { t( `global.${tagged}` ) }
                </span>
            )
        }
    </div>
)

export default UserItem
