import { createStore, applyMiddleware } from 'redux'
import thunk                            from 'redux-thunk'
import { addReducers, rootReducer }     from 'reduxigen'
import moment                           from 'moment'
import { i18nState }                    from 'redux-i18n'

import config from 'config'

const
    saved = {
        account: sessionStorage.getItem( `${config.ui.prefix}_account` ),
        token:   sessionStorage.getItem( `${config.ui.prefix}_token` )
    }

if ( saved.account === 'undefined' ) {
    sessionStorage.removeItem( `${config.ui.prefix}_account` )
    saved.account = null
}

if ( saved.account === 'undefined' ) {
    sessionStorage.removeItem( `${config.ui.prefix}_account` )
    saved.account = null
}

export const initialState = {
    i18nState: {
        lang:         'en',
        translations: {},
        forceRefresh: false,
    },
    auth: {
        info:    { token: saved.token },
        lastTry: moment()
    },
    companies: {
        current: null,
        list:    []
    },
    domains: {},
    sharing: { lists: {} },
    tags:    [],
    todo:    { list: {} },
    wallets: {
        user:      {},
        corporate: {},
        cards:     {},
        tags:      {}
    },
    user:   { account: saved.account },
    public: {
        steps: {
            current: 0,
            order:   [ 'device', 'base', 'email', 'contacts', 'phone', 'final', 'exchange' ],
            status:  {
                device:   false,
                base:     false,
                email:    false,
                contacts: false,
                phone:    false,
                final:    false,
                exchange: false
            },
            data: {
                device:   null,
                base:     null,
                email:    null,
                contacts: null,
                phone:    null,
                final:    null,
                exchange: null
            }
        }
    },
    saved
}

addReducers([ i18nState ])

export default createStore(
    rootReducer( initialState ),
    applyMiddleware( thunk )
)
