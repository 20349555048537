import React         from 'react'
import { Table }     from 'antd'
import { Resizable } from 'react-resizable'

import { generate, copy } from 'tools'

const
    ResizableTitle = props => {
        const
            { onResize, width, minWidth, maxWidth, lockWidth, ...restProps } = props,
            stopper = e => e.stopPropagation()

        return (
            <Resizable
                width = { width === '100%' ? 9999 : width }
                height = { 0 }

                minConstraints = {
                    lockWidth
                        ? [ width === '100%' ? 9999 : width, 0 ]
                        : [ minWidth === void 0 ? 60 : minWidth, 0 ]
                }

                maxConstraints = {
                    lockWidth
                        ? [ width === '100%' ? 9999 : width, 0 ]
                        : [ maxWidth === void 0 ? 1000 : maxWidth, 0 ]
                }

                draggableOpts = {{ enableUserSelectHack: false }}
                handle = {
                    <span
                        className = "react-resizable-handle"
                        onClick = { stopper }
                    />
                }

                onResize = { onResize }
            >
                <th {...restProps} />
            </Resizable>
        )
    }

class ResizableTable extends React.Component {

    components = { header: { cell: ResizableTitle, }, }

    constructor ( props ) {
        super( props )

        this.state = { columns: props.columns ? [ ...props.columns ] : [] }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { columns } = this.props

        ;( columns !== prevProps.columns ) && ( this.set.columns([ ...columns ]))
    }

    onResize = index => ( e, { size }) => {
        const
            { onResize } = this.props,
            columns = copy.array( this.state.columns, true ),
            min = columns[ index ].minWidth || 60,
            max = columns[ index ].maxWidth || 1000

        columns[ index ].width = Math.max( min, Math.min( max, size.width ))
        this.set.columns( columns )

        ;( onResize ) && ( onResize( columns ))
    }

    apply = row => ({
        minWidth:  row.minWidth,
        maxWidth:  row.maxWidth,
        lockWidth: row.lockWidth
    })

    getRest = () => Math.max( 60, window.innerWidth - 64 - this.state.columns.reduce(( summ, col ) => summ + ( col.width || 60 ), 0 ))

    render () {
        const
            columns = this.state.columns.map(( col, index ) => ({
                ...col,
                onHeaderCell: column => ({
                    minWidth:  column.minWidth,
                    maxWidth:  column.maxWidth,
                    lockWidth: column.lockWidth,
                    width:     column.width === 'auto' ? this.getRest() : column.width,
                    onResize:  this.onResize( index ),
                }),
            }))

        return (
            <Table
                { ...this.props }

                components = { this.components }
                columns = { columns }
            />
        )
    }
}

export default ResizableTable
