

import './floating-button.scss'

const FloatingButton = ({ onClick }) => (
    <span
        className = "floating-button"
        onClick = { onClick }
    />
)

export default FloatingButton
