import React     from 'react'
import PropTypes from 'prop-types'

import { AdminContent, TodoCompanion, TodoTable } from 'components'

import './todo-content.scss'

class TodoContent extends React.Component {

    static propTypes = {
        search:   PropTypes.string,
        todos:    PropTypes.array,
        filtered: PropTypes.array,
        tags:     PropTypes.array,

        getTag:   PropTypes.func,
        onFilter: PropTypes.func,
    }

    render () {
        const
            { todos, filtered, search, tags } = this.props

        return (
            <AdminContent>
                <div className="todo-content">
                    <div className="todo-companion-container">
                        <TodoCompanion
                            todos = { todos }
                            tags = { tags }
                            search = { search }

                            getTag = { this.props.getTag }
                            onFilter = { this.props.onFilter }
                        />
                    </div>
                    <div className="todo-table-container">
                        <TodoTable
                            todos = { filtered }
                            search = { search }
                        />
                    </div>
                </div>
            </AdminContent>
        )
    }
}

export default TodoContent
