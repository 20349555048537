import React     from 'react'
import TabeeAuth from '@tabee/frontend-auth'

import {
    StaticPage,
    SignMethods,
    SiteNavigation,
    SocialNetworks,
    AccountContainer,
    AccountFooter,
    Spinner
} from 'components'

import { t } from 'tools'

import connector from './connector'

class SignUp extends React.Component {

    componentDidMount () {
        TabeeAuth.SSOSignUp( `${window.location.host}/auth/sso` )
    }

    render () {
        return (
            <StaticPage>

                <SignMethods />

                <AccountContainer title={t( 'login.loginTitle' )}>

                    <div className="center-container">
                        <Spinner />
                    </div>

                </AccountContainer>

                <AccountFooter>
                    <SiteNavigation />
                    <SocialNetworks />
                </AccountFooter>

            </StaticPage>
        )
    }
}

export default connector( SignUp )
