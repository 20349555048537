import Adjust from '@adjustcom/adjust-web-sdk'

const
    mapping = {
        activation_started:   '11uq6p',
        activation_social:    'hl4ene',
        activation_completed: 'j4ytne',

        form_started:       '1pgeax',
        form_social:        'khbdic',
        form_completed:     'vfyb64',
        form_self:          'e5bppu',
        form_existing:      'kbjvz2',
        wallet_after_share: '9oah0m',

        contact_add: 'o7wfq2',

        contact_hide: 'l0fvdw',
        contact_show: '1q2vbh',

        onboarding_stick: 'e0bjyf',

        onboarding_wallet:       '9oah0m',
        onboarding_shared:       'm0fn51',
        onboarding_photo:        '4foo8q',
        onboarding_request:      '6a57dj',
        onboarding_try:          '2bzhf3',
        onboarding_digital_next: 'zg0adm'
    },

    extractParams = obj => {
        const
            res = []

        if ( !obj ) { return res }

        Object.keys( obj ).forEach( key => {
            res.push({
                key,
                value: obj[ key ]
            })
        })

        return res
    },

    track = ( name, params ) => {
        console.log( 'adjust:', name )

        if ( mapping[ name ]) {
            Adjust.trackEvent({
                eventToken:     mapping[ name ],
                callbackParams: extractParams( params )
            })
        } else {
            Adjust.trackEvent({
                eventToken:     name,
                callbackParams: extractParams( params )
            })
        }
    },

    exp = { track }

export default exp
