import React              from 'react'
import PropTypes          from 'prop-types'
import { Input }          from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { AdminHeader, ToolsRow, Spinner } from 'components'
import { strings, t }                     from 'tools'

class TodoHeader extends React.Component {

    static propTypes = {
        loading: PropTypes.bool,
        account: PropTypes.object,
        search:  PropTypes.string,

        count: PropTypes.number,
        total: PropTypes.number,

        onTogglePanel: PropTypes.func,
        onSearch:      PropTypes.func
    }

    render () {
        const
            { search, loading, count, total, account } = this.props

        return (
            <AdminHeader account={account} onTogglePanel={this.props.onTogglePanel}>
                <div className="title-fix">
                    { loading && <Spinner style={{ marginRight: 20 }} /> }
                    {
                        loading
                            ? (
                                    count > 0 && !!total
                                        ? t( 'todo.todoLoaded', { count: Math.round( count / total * 100 ) })
                                        : t( 'todo.loadingTodo' )
                                )
                            : (
                                    count !== total
                                        ? t( 'todo.outOfReminders', { count, total })
                                        : strings.encount( count, t( 'counters.countersReminder' ))
                                )
                    }
                </div>

                <ToolsRow>
                    <Input
                        allowClear
                        prefix = { <SearchOutlined /> }
                        value = { search }
                        placeholder = { t( 'global.search' ) }

                        onChange = { this.props.onSearch }
                    />
                </ToolsRow>
            </AdminHeader>
        )
    }
}

export default TodoHeader
