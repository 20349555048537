import React      from 'react'
import PropTypes  from 'prop-types'
import classNames from 'classnames'

import './form-section.scss'

class FormSection extends React.Component {

    static propTypes = { title: PropTypes.string }

    render () {
        const
            { title, children, className, contentClassName } = this.props

        return (
            <div className={classNames( 'form-section', className )}>
                {
                    title && (
                        <h4 className="form-section-title">{ title }</h4>
                    )
                }
                <section className={classNames( 'form-section-content', contentClassName )}>
                    { children }
                </section>
            </div>
        )
    }
}

export default FormSection
