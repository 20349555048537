import React                                   from 'react'
import PropTypes                               from 'prop-types'
import { Drawer, Form, Input, Button, Select } from 'antd'

import { FormSection } from 'components'
import { t, strings }  from 'tools'
import config          from 'config'

class EditMemberForm extends React.Component {

    static propTypes = {
        open:    PropTypes.bool,
        domains: PropTypes.array,

        onClose:  PropTypes.func,
        onInvite: PropTypes.func
    }

    domains = () => {
        const
            { domains } = this.props,
            res = []

        domains.forEach( domain => res.push(
            <Select.Option key={domain} value={domain}>
                {domain}
            </Select.Option>
        ))

        return res
    }

    render () {
        const
            { open, domains } = this.props

        return (
            <Drawer
                title = ""
                placement = "right"
                closable = { false }
                getContainer = { false }
                className = "drawer-form invite-form"

                visible = { open }
                style = {{ position: 'absolute' }}
                width = { 640 }

                onClose = { this.props.onClose }
            >
                <div className="edit-contact-form" style={{ width: 640 }}>
                    <header className="form-header">
                        <h3>{ t( 'team.teamAdd' ) }</h3>
                        <Button onClick={this.props.onClose}>
                            { t( 'global.close' ) }
                        </Button>
                        <Button type="primary" onClick={this.props.onInvite}>
                            { t( 'global.invite' ) }
                        </Button>
                    </header>
                    <section className="form-content">
                        <Form { ...config.forms.layout } fields={this.state}>

                            <FormSection>
                                <Form.Item label={t( 'team.inviteEmail' )} className="flexrow">
                                    <Input name="invite" />
                                    <Select name="domain" defaultValue={domains[ 0 ] || null}>
                                        { this.domains() }
                                    </Select>
                                </Form.Item>
                            </FormSection>

                            <FormSection title={ `${t( 'team.teamMemberSettings' )} (${strings.capitalize( t( 'global.optional' ))})` }>
                                <Form.Item name="username" label={t( 'team.teamUsername' )}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="role" label={t( 'team.teamAccountRole' )}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="department" label={t( 'team.teamDepartment' )}>
                                    <Input />
                                </Form.Item>
                            </FormSection>
                        </Form>
                    </section>
                </div>
            </Drawer>
        )
    }
}

export default EditMemberForm
