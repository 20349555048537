
import classNames from 'classnames'

import './tag.scss'

const Tag = ({ name, deleted, color }) => {
    const
        cls = classNames(
            'tag',
            deleted && 'deleted'
        ),
        style = color
            ? { backgroundColor: color }
            : {}

    return (
        <span className={cls}>
            <span className="tag-icon" style={style} />
            <span className="tag-name">{name}</span>
        </span>
    )
}

export default Tag
