import React                           from 'react'
import PropTypes                       from 'prop-types'
import { Checkbox, Radio, DatePicker } from 'antd'

import { Tag, Option, UserItem } from 'components'
import { i18next, dateLocales }  from 'tools'

const
    { OptionGroup, OptionItem, OptionName } = Option

class OptionItems extends React.Component {

    static propTypes = {
        allowAll: PropTypes.string,

        data:     PropTypes.object,
        name:     PropTypes.string,
        selected: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]),

        onCheck:  PropTypes.func,
        onModify: PropTypes.func
    }

    componentDidMount () {
        i18next.on( 'languageChanged', this.fupdate )
    }

    componentWillUnmount () {
        i18next.off( 'languageChanged', this.fupdate )
    }

    fupdate = () => this.forceUpdate()

    check = item => e => {
        if ( !item.id ) {
            this.props.onCheck( item )( null )
            return
        }

        this.props.onCheck( item )( e )
    }

    change = item => raw => {
        const
            { data } = this.props,
            value = data.formatter
                ? data.formatter( raw )
                : raw

        this.props.onModify( item, value )
    }

    checked = item => {
        const
            { selected } = this.props

        if (( !selected || selected.length === 0 ) && !item.id ) { return true }

        return (
            Array.isArray( selected )
                ? selected.indexOf( item.id ) > -1
                : !!selected[ item.id ]
        )
    }

    value = item => {
        const
            { selected, data } = this.props,
            def = item.nullable ? null : item.def,
            value = data.parser
                ? data.parser( selected[ item.id ] || def )
                : selected[ item.id ] || def

        return value
    }

    view = ( data, item ) => {
        if ( !item.id ) {
            return <OptionName>{ item.name }</OptionName>
        }

        switch ( data.type ) {
            case 'range':
                return (
                    <OptionName>
                        { item.name }:
                        <DatePicker
                            value = { this.value( item ) }
                            locale = { dateLocales[ i18next.language ] }

                            onChange = { this.change( item ) }
                        />
                    </OptionName>
                )
            case 'tags':
                return <Tag deleted={item.is_deleted} name={item.name} color={item.colour} />
            case 'members':
                return <UserItem name={item.name} desc={item.desc} tagged={item.tagged} src={item.image} />
            default:
                return <OptionName>{ item.name }</OptionName>
        }
    }

    checkbox = item =>
        (
            <Checkbox
                className = "small-size"
                value = { item.id || '' }
                checked = { this.checked( item ) }

                onChange = { this.check( item ) }
            />
        )

    radio = item =>
        (
            <Radio
                className = "small-size"
                value = { item.id || '' }
                checked = { this.checked( item ) }

                onChange = { this.check( item ) }
            />
        )

    item = data => item => {
        const
            name = this.view( data, item )

        return (
            <OptionItem key={item.id} className={item.className}>
                { name }
                <div>
                    {
                        data && !data.simple && (
                            data.selection
                                ? this.checkbox( item )
                                : this.radio( item )
                        )
                    }
                </div>
            </OptionItem>
        )
    }

    render () {
        const
            { allowAll, name, data } = this.props

        return (
            <OptionGroup name={name}>
                {
                    !!allowAll && (
                        this.item( data )({
                            id:        null,
                            name:      allowAll,
                            className: 'option-all'
                        })
                    )
                }
                {
                    data.items.map( this.item( data ))
                }
            </OptionGroup>
        )
    }
}

export default OptionItems
