import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PublicLoader } from 'components'
import config           from 'config'

const
    mapping = {
        digital:  'digital',
        promo:    'p',
        identity: 'u',
        found:    'found'
    }

const PublicCardRedirect = () => {
    const
        { id } = useParams(),
        type = window.location.pathname.split( '/' )[ 1 ].toLowerCase()

    useEffect(() => {
        if ( !id ) {
            window.location.href = config.sso.url
        } else {
            window.location.href = window.location.search
                ? `${config.id.url}/${mapping[ type ] || mapping.identity}/${id}${window.location.search}`
                : `${config.id.url}/${mapping[ type ] || mapping.identity}/${id}`
        }
    }, [])

    return <><PublicLoader visible /></>
}

export default PublicCardRedirect
