
import classNames from 'classnames'

const Wrapper = className => props =>
    (
        <div {...props} className={classNames( className, props.className )}>
            { props.children }
        </div>
    )

export default Wrapper
