import React                           from 'react'
import PropTypes                       from 'prop-types'
import { Button, Form, Input, Drawer } from 'antd'

import { ContactCardViewer, FormSection, OptionItems, Spinner } from 'components'
import { normalize, t }                                         from 'tools'
import config                                                   from 'config'

import './edit-contact-form.scss'

class EditContactForm extends React.Component {

    static propTypes = {
        editable: PropTypes.bool,
        contact:  PropTypes.object,
        tags:     PropTypes.array,

        onClose: PropTypes.func,
        onSave:  PropTypes.func
    }

    test = a => b => console.log( 'test', a, b )

    tagsConfig = ( tags, selected ) =>
        ({
            id:        'tags',
            name:      '',
            type:      'tags',
            selection: true,
            items:     ( tags || [])
                .filter( tag => !tag.is_deleted || selected.indexOf( tag.id ) > -1 )
                .sort(( a, b ) => {
                    if ( selected.indexOf( a.id ) > -1 && selected.indexOf( b.id ) < 0 ) { return -1 }
                    if ( selected.indexOf( a.id ) < 0 && selected.indexOf( b.id ) > -1 ) { return 1 }
                    return 0
                })
        })

    render () {
        const
            { editable, tags, open, contact } = this.props,
            normalized = contact ? normalize.link( contact ) : {},
            selected = contact ? normalized.tags.map( t => t.id ) : []

        return (
            <Drawer
                title = ""
                placement = "right"
                closable = { false }
                getContainer = { false }
                className = "edit-contact-form"

                visible = { open }
                style = {{ position: 'absolute' }}
                width = { 640 }

                onClose = { this.props.onClose }
            >
                {
                    contact && (
                        <div className="drawer-form">
                            <header className="form-header">
                                <h3>{ editable ? t( 'wallets.editContact' ) : t( 'wallets.contactInfo' ) }</h3>
                                <Button onClick={this.props.onClose}>
                                    { t( 'global.close' ) }
                                </Button>
                                {
                                    editable && (
                                        <Button type="primary" onClick={this.props.onSave}>
                                            { t( 'global.save' ) }
                                        </Button>
                                    )
                                }
                            </header>
                            <section className="form-content">
                                <ContactCardViewer
                                    file = { normalized.file }
                                    photo = { normalized.photo }
                                    logo = { normalized.companyLogo }
                                />
                                <Form { ...config.forms.layout } fields={normalize.form( normalized )}>

                                    <FormSection>
                                        <Form.Item name="firstName" label={t( 'columns.columnsFirstName' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                        <Form.Item name="lastName" label={t( 'columns.columnsLastName' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                    </FormSection>

                                    <FormSection>
                                        <Form.Item name="companyName" label={t( 'columns.columnsCompanyName' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                        <Form.Item name="position" label={t( 'columns.columnsPosition' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                    </FormSection>

                                    <FormSection title={t( 'columns.columnsContacts' )}>
                                        <Form.Item name="email" label={t( 'columns.columnsEmail' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                        <Form.Item name="phone" label={t( 'columns.columnsPhone' )}>
                                            <Input disabled={!editable} />
                                        </Form.Item>
                                    </FormSection>

                                    <FormSection title={t( 'columns.columnsTags' )}>
                                        {
                                            tags
                                                ? (
                                                        <OptionItems
                                                            key = "tags"
                                                            name = ""
                                                            data = { this.tagsConfig( editable ? tags : normalized.tags, selected ) }
                                                            selected = { selected }

                                                            onCheck = { this.test }
                                                        />
                                                    )
                                                : <Spinner />
                                        }
                                    </FormSection>
                                </Form>
                            </section>
                        </div>
                    )
                }
            </Drawer>
        )
    }
}

export default EditContactForm
