import React      from 'react'
import { Button } from 'antd'
import classNames from 'classnames'

import {
    StaticPage,
    PublicCardHeader,
    Spinner,
    Image,
    PublicLoader
} from 'components'

import AppleWallet   from '../PublicCard/applewallet.svg'
import WalletPreview from './wallet_preview.png'

import { generate, t, copy, adjust } from 'tools'

import connector from './connector'
import './digital-wallet.scss'

const defaultPointIcon = 'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgZmlsbD0ibm9uZSI+DQogIDxwYXRoIGZpbGw9InVybCgjYSkiIGQ9Ik0yMy43MzkxIDBjLTIuMzQ1OSAwLTQuMjYwOCAxLjkxNDg3LTQuMjYwOCA0LjI2MDg3IDAgMS4xMjE4My40NDA5IDIuMTQxOTYgMS4xNTQ0IDIuOTA0MzZsLTUuMDk1NSA2LjIyNjA3SDguNDczMDNjLS4yOTc3Ni0yLjA1ODktMi4wNzMzMS0zLjY1MjE4LTQuMjEyMTYtMy42NTIxOEMxLjkxNDg3IDkuNzM5MTIgMCAxMS42NTQgMCAxNHMxLjkxNDg3IDQuMjYwOSA0LjI2MDg3IDQuMjYwOWMyLjEzODg1IDAgMy45MTQ0LTEuNTkzMyA0LjIxMjE2LTMuNjUyMmg3LjA0OTg3bDQuODg5OCA2LjQ3NjljLS41ODM0LjcyOTItLjkzNDQgMS42NTExLS45MzQ0IDIuNjUzNSAwIDIuMzQ2IDEuOTE0OSA0LjI2MDkgNC4yNjA4IDQuMjYwOUMyNi4wODUxIDI4IDI4IDI2LjA4NTEgMjggMjMuNzM5MXMtMS45MTQ5LTQuMjYwOC00LjI2MDktNC4yNjA4Yy0uOTAzNyAwLTEuNzQxNC4yODY4LTIuNDMyMy43NzAzbC00LjcwNjctNi4yMzMxIDQuOTgxMy02LjA4OTRjLjYzNDEuMzc1MzYgMS4zNzA0LjU5NTYzIDIuMTU3Ny41OTU2MyAyLjM0NiAwIDQuMjYwOS0xLjkxNDg2IDQuMjYwOS00LjI2MDg2UzI2LjA4NTEgMCAyMy43MzkxIDBabTAgMS4yMTczOWMxLjY4ODEgMCAzLjA0MzUgMS4zNTU0IDMuMDQzNSAzLjA0MzQ4IDAgMS42ODgxMS0xLjM1NTQgMy4wNDM0Ny0zLjA0MzUgMy4wNDM0Ny0xLjY4OCAwLTMuMDQzNS0xLjM1NTM2LTMuMDQzNS0zLjA0MzQ3IDAtMS42ODgwOCAxLjM1NTUtMy4wNDM0OCAzLjA0MzUtMy4wNDM0OFpNNC4yNjA4NyAxMC45NTY1YzEuNjg4MSAwIDMuMDQzNDYgMS4zNTU0IDMuMDQzNDYgMy4wNDM1cy0xLjM1NTM2IDMuMDQzNS0zLjA0MzQ2IDMuMDQzNWMtMS42ODgwNyAwLTMuMDQzNDgtMS4zNTU0LTMuMDQzNDgtMy4wNDM1czEuMzU1NDEtMy4wNDM1IDMuMDQzNDgtMy4wNDM1Wm0xOS40NzgyMyA5LjczOTFjMS42ODgxIDAgMy4wNDM1IDEuMzU1NSAzLjA0MzUgMy4wNDM1IDAgMS42ODgxLTEuMzU1NCAzLjA0MzUtMy4wNDM1IDMuMDQzNS0xLjY4OCAwLTMuMDQzNS0xLjM1NTQtMy4wNDM1LTMuMDQzNSAwLTEuNjg4IDEuMzU1NS0zLjA0MzUgMy4wNDM1LTMuMDQzNVoiLz4NCiAgPGRlZnM+DQogICAgPGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iNS43NDQxNiIgeDI9IjMwLjIwODIiIHkxPSItLjA0NjEyMTMiIHkyPSIyLjAxNjAxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQogICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjMEYxRTM1Ii8+DQogICAgICA8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM5QTlBOUEiLz4NCiAgICA8L2xpbmVhckdyYWRpZW50Pg0KICA8L2RlZnM+DQo8L3N2Zz4='

class DigitalWallet extends React.Component {

    timer = null

    constructor ( props ) {
        super( props )

        this.state = {
            cards:   [],
            current: 0,
            swap:    false,
            loading: false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.props.getProjectConfig( t( 'global.projectID' ))

        this.timer = setInterval( this.nextCard, 3000 )
        document.body.classList.add( 'digital-wallet-body' )
    }

    componentWillUnmount () {
        clearInterval( this.timer )
        document.body.classList.remove( 'digital-wallet-body' )
    }

    componentDidUpdate ( prevProps ) {
        const
            { config, precreated } = this.props

        if ( precreated !== prevProps.precreated && precreated ) {
            adjust.track( 'onboarding_digital_next' )
            setTimeout(() => window.location.href = `/digital/${precreated.card.id}${window.location.search || ''}`, 200 )
        }

        if ( config !== prevProps.config && config ) {
            this.updateCards( config?.cards || [])
        }
    }

    updateCards = cards => this.set.state({ cards: [ ...cards, { ...cards?.[ 0 ], key: `${cards?.[ 0 ]?.key}-clone` } ], current: 0 })

    nextCard = () => {
        const
            { current, cards } = this.state

        let
            next = current + 1

        if ( next >= cards.length ) {
            //Clone remove
            this.set.swap( true )
            setTimeout(() => {
                this.set.swap( false )
                this.nextCard()
            }, 1000 )
        }

        if ( next >= cards.length ) {
            next = 0
        }

        this.set.current( next )
    }

    removeCard = card => () => {
        const
            cards = copy.array( this.state.cards )

        cards.splice( cards.findIndex( d => d.key === card.key ), 1 )

        this.set.cards( cards )
    }

    checkPoint = cfg => !!cfg.text

    createCard = () => {
        this.props.precreateCard({
            project:        'b2b59f20-9112-11ec-8043-6a13bef2453b',
            company_design: null
        })
        this.set.loading( true )
    }

    render () {
        const
            { config } = this.props,
            { cards, current, loading, swap } = this.state

        return (
            <StaticPage>
                <div className="public-card-container digital-wallet-container">
                    <PublicCardHeader />
                    {
                        !!config && (
                            <div className='digital-wallet-content'>
                                <h2>{ t( `digitalWallet.${config.header}` ) }</h2>
                                <p>{ t( `digitalWallet.${config.content}` ) }</p>
                            </div>
                        )
                    }
                    <ul className='digital-wallet-points'>
                        {
                            config?.points?.filter( this.checkPoint ).map( cfg => (
                                <li className='dw-point' key={cfg.text}>
                                    <Image
                                        resize = { false }
                                        fallback = { defaultPointIcon }
                                        src = { cfg.icon }
                                        alt = ""
                                    />
                                    <span className='dw-point-text'>
                                        { t( `digitalWallet.${cfg.text}` ) }
                                    </span>
                                </li>
                            ))
                        }
                    </ul>
                    <div className='digital-wallet-sticky'>
                        {
                            !!config && (
                                <div className='digital-wallet-buttons'>
                                    <img src={AppleWallet} alt="Add to Apple Wallet" onClick={this.createCard} />
                                    <Button type="primary" onClick={this.createCard}>
                                        { t( `digitalWallet.${config.button}` ) }
                                    </Button>
                                </div>
                            )
                        }
                        <div className='digital-wallet-preview'>
                            <img src={WalletPreview} alt="" />
                            <div className={classNames( 'dw-preview-container', swap && 'swap-clone' )}>
                                {
                                    cards.map(( card, index ) => (
                                        <div
                                            key = { card.key }
                                            className = { classNames( 'dw-preview-card', index === current && 'dw-card-current' ) }
                                            style = {{
                                                backgroundColor: card.bg_color,
                                                color:           card.text_color,
                                                zIndex:          9999 - index
                                            }}
                                        >
                                            <Image
                                                resize = { false }
                                                fallback = { defaultPointIcon }
                                                src = { card.logo }
                                                alt = ""

                                                onFail = { this.removeCard( card ) }
                                            />
                                            <div className='dw-preview-verified'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 20" fill="none">
                                                    <path fill={card.special_color} d="M2.73828 5.99999 4.60859.926941h-.66445L2.45352 5.18085h-.05625L.906641.926941H.242188L2.1125 5.99999h.62578Z"/>
                                                    <path fill={card.special_color} d="M8.64102 5.43046H6.13086V3.68671h2.38008v-.5625H6.13086V1.49647h2.51016V.926941H5.49805V5.99999h3.14297v-.56953Z"/>
                                                    <path fill={card.special_color} d="M10.4199 1.48944h1.2199c.654 0 1.0477.36211 1.0477.97031 0 .62227-.3691.97032-1.0195.97032h-1.2481V1.48944Zm0 2.50313h1.2481l1.0863 2.00742h.7418l-1.1742-2.10586c.6363-.20039 1.0195-.74883 1.0195-1.45547 0-.91406-.6398-1.511719-1.6172-1.511719H9.78711V5.99999h.63279V3.99257Z"/>
                                                    <path fill={card.special_color} d="M15.124 5.99999V.926941h-.6328V5.99999h.6328Z"/>
                                                    <path fill={card.special_color} d="M17.0508 5.99999V3.80272h2.2465v-.5625h-2.2465V1.49647h2.4469V.926941H16.418V5.99999h.6328Z"/>
                                                    <path fill={card.special_color} d="M21.1709 5.99999V.926941h-.6328V5.99999h.6328Z"/>
                                                    <path fill={card.special_color} d="M25.6078 5.43046h-2.5101V3.68671h2.38v-.5625h-2.38V1.49647h2.5101V.926941h-3.143V5.99999h3.143v-.56953Z"/>
                                                    <path fill={card.special_color} d="M26.7539.926941V5.99999h1.7402c1.5082 0 2.3942-.93867 2.3942-2.53828 0-1.5961-.8895-2.534769-2.3942-2.534769h-1.7402Zm.6328.569529h1.0653c1.125 0 1.7894.73125 1.7894 1.97227 0 1.2375-.6574 1.96172-1.7894 1.96172h-1.0653V1.49647Z"/>
                                                    <path fill={card.special_color} d="M35.993 5.99999c1.0793 0 1.7191-.53086 1.7191-1.42383 0-.67148-.4394-1.14609-1.132-1.23398v-.05625c.4887-.0879.8754-.56602.8754-1.09336 0-.77696-.5696-1.265629-1.5012-1.265629h-1.9406V5.99999h1.9793Zm-1.3465-4.51406h1.1742c.6293 0 .9949.29882.9949.81562 0 .53789-.3902.81211-1.1566.81211h-1.0125V1.48593Zm0 3.95507V3.65858h1.1918c.8086 0 1.2234.29883 1.2234.88594s-.4008.89648-1.1637.89648h-1.2515Z"/>
                                                    <path fill={card.special_color} d="M40.6369 5.99999V3.81678l1.8-2.889839h-.7207L40.3486 3.18046h-.0562L38.9248.926941h-.7207l1.8 2.889839v2.18321h.6328Z"/>
                                                    <path fill={card.text_color} d="M4.33008 19v-7.5059h2.72461v-.9492H.550781v.9492H3.27539V19h1.05469Z"/>
                                                    <path fill={card.text_color} d="M9.62109 18.2265c-.73242 0-1.27734-.375-1.27734-1.0195 0-.6328.42187-.9668 1.38281-1.0312l1.69924-.1114v.5801c0 .9024-.7676 1.582-1.80471 1.582Zm-.1875.8848c.84371 0 1.53511-.3691 1.94531-1.043h.0938V19h.9609v-4.3242c0-1.3125-.8613-2.1035-2.4024-2.1035-1.34761 0-2.3437.6679-2.47847 1.6816h1.01954c.14062-.4981.66796-.7852 1.42382-.7852.94341 0 1.42971.4278 1.42971 1.2071v.5742l-1.82228.1113c-1.47071.0879-2.30274.7383-2.30274 1.8692 0 1.1543.9082 1.8808 2.13281 1.8808Z"/>
                                                    <path fill={card.text_color} d="M17.4375 19.1113c1.5996 0 2.6484-1.2949 2.6484-3.2695 0-1.9863-1.0429-3.2695-2.6484-3.2695-.8672 0-1.6348.4277-1.9805 1.1074h-.0937v-3.5039h-1.0078V19h.9609v-1.0078h.0938c.3984.7031 1.1484 1.1191 2.0273 1.1191Zm-.2344-5.6308c1.1543 0 1.8399.8847 1.8399 2.3613 0 1.4765-.6856 2.3613-1.8399 2.3613-1.1484 0-1.8633-.9023-1.8633-2.3613s.7149-2.3613 1.8633-2.3613Z"/>
                                                    <path fill={card.text_color} d="M24.1875 13.4629c1.002 0 1.6699.7383 1.6934 1.8574h-3.4805c.0762-1.1191.7793-1.8574 1.7871-1.8574Zm1.6641 3.9023c-.2637.5567-.8145.8555-1.6289.8555-1.0723 0-1.7696-.791-1.8223-2.0391v-.0468h4.541v-.3868c0-1.9629-1.0371-3.1757-2.7422-3.1757-1.7344 0-2.8476 1.289-2.8476 3.2753 0 1.9981 1.0957 3.2637 2.8476 3.2637 1.3828 0 2.3555-.6621 2.6602-1.7461h-1.0078Z"/>
                                                    <path fill={card.text_color} d="M31.043 13.4629c1.0019 0 1.6699.7383 1.6933 1.8574h-3.4804c.0761-1.1191.7793-1.8574 1.7871-1.8574Zm1.664 3.9023c-.2636.5567-.8144.8555-1.6289.8555-1.0722 0-1.7695-.791-1.8222-2.0391v-.0468h4.541v-.3868c0-1.9629-1.0371-3.1757-2.7422-3.1757-1.7344 0-2.8477 1.289-2.8477 3.2753 0 1.9981 1.0957 3.2637 2.8477 3.2637 1.3828 0 2.3555-.6621 2.6601-1.7461H32.707Z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    {
                        loading && (
                            <div className="center-container loading-overlay"><Spinner/></div>
                        )
                    }
                    <PublicLoader
                        visible = { !config }
                    />
                </div>
            </StaticPage>
        )
    }
}

export default connector( DigitalWallet )
