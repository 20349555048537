
import { Link } from 'react-router-dom'

import { Icons } from 'components'
import { t }     from 'tools'
import config    from 'config'

import './sign-methods.scss'

/* TODO: Make links working */

const SignMethods = ({ hideSignUp }) => (
    <div className='sign-methods'>
        {
            !hideSignUp
                ? (
                        <div className='registration'>
                            { t( 'login.dontHaveAccount' ) } <Link to={config.links.signup}>{ t( 'login.loginSignUp' ) }</Link>.
                        </div>
                    )
                : (
                        <div className='registration'>
                            { t( 'register.haveAccount' ) } <Link to={config.links.login}>{ t( 'register.registerSignIn' ) }</Link>.
                        </div>
                    )
        }
        <div className='apps'>
            <a href={config.links.ios} className='app'>
                <Icons.IOSApp extraClassName='app-icon' />
                <span>
                    { t( 'global.getIOS' ) }
                </span>
            </a>
            <a href={config.links.android} className='app'>
                <Icons.AndroidApp extraClassName='app-icon' />
                <span>
                    { t( 'global.getAndroid' ) }
                </span>
            </a>
        </div>
    </div>
)

export default SignMethods
