import React                           from 'react'
import { Form, Input, Button, Select } from 'antd'

import { AdminPanel, AdminHeader, FormSection, Spinner, Image, ColorPicker } from 'components'
import { generate, t, i18next, normalize }                                   from 'tools'
import config                                                                from 'config'

import connector from './connector'
import './company.scss'

class Company extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {}

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { company, account } = this.props

        ;( company && account !== company.id ) && ( this.props.flushCompanyInfo())
        this.props.getCompanyInfo( account )
    }

    componentDidUpdate ( prevProps ) {
        const
            { user, account, history } = this.props

        if ( account !== prevProps.account ) {
            if ( !account === user.id ) {
                history.push( '/account' )
            } else {
                this.props.flushCompanyInfo()
                this.props.getCompanyInfo( account )
            }
        }
    }

    langs = () => {
        const
            res = [],
            langs = i18next.options.resources

        Object.keys( langs ).forEach( lang => {
            res.push(
                <Select.Option key={lang}>
                    { langs[ lang ].main.global.language }
                </Select.Option>
            )
        })

        return res
    }

    countries = () =>
        (
            config.countries.map( country => (
                <Select.Option key={country.code}>
                    { country.name }
                </Select.Option>
            ))
        )

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { company, current, user, companies, match } = this.props

        return (
            <React.Fragment>

                <AdminPanel
                    account = { current }
                    user = { user }
                    companies = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onExpand = { this.closeDrawer }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard company-settings-dashboard">
                    <AdminHeader account={current} onTogglePanel={this.panelToggle}>
                        <div className="title-fix">
                            { t( 'company.editCompanyDetails' ) }
                        </div>

                    </AdminHeader>
                    <div className="admin-dashboard-content">
                        {
                            company
                                ? (
                                        <Form { ...config.forms.layout } fields={normalize.form( company )}>

                                            <FormSection>
                                                <Form.Item name="name" label={t( 'company.companyName' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="description" label={t( 'company.companyDescription' )}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                            </FormSection>
                                            <hr/>
                                            <FormSection>
                                                <Form.Item name="language" label={t( 'company.companyLanguage' )}>
                                                    <Select>
                                                        { this.langs() }
                                                    </Select>
                                                </Form.Item>
                                            </FormSection>

                                            <FormSection title={t( 'company.companyContacts' )}>
                                                <Form.Item name="email" label={t( 'company.companyEmail' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="phone" label={t( 'company.companyPhone' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="url" label={t( 'company.companyWeb' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="address" label={t( 'company.companyAddress' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="street" label={t( 'company.companyStreet' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="secondaryAddress" label={t( 'company.companySecondaryAddress' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="city" label={t( 'company.companyCity' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="region" label={t( 'company.companyStateRegion' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="zip" label={t( 'company.companyZipCode' )}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="country" label={t( 'company.companyCountry' )}>
                                                    <Select>
                                                        { this.countries() }
                                                    </Select>
                                                </Form.Item>
                                            </FormSection>

                                            <FormSection title={t( 'company.companyDesign' )}>
                                                <Form.Item name="logo" label={t( 'company.companyLogo' )}>
                                                    <Image
                                                        className = "logo"
                                                        fallback = {'https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png'}
                                                        src = { company.square_logo }
                                                        alt = { company.name }
                                                        width = "95"
                                                        height = "95"
                                                    />
                                                </Form.Item>
                                                <Form.Item name="colour" label={t( 'company.companyColor' )}>
                                                    <ColorPicker />
                                                </Form.Item>
                                            </FormSection>

                                            <FormSection contentClassName="inline-row">
                                                <Button type="text">
                                                    { t( 'global.cancel' ) }
                                                </Button>
                                                <Button type="primary">
                                                    { t( 'global.save' ) }
                                                </Button>
                                            </FormSection>
                                        </Form>
                                    )
                                : (
                                        <div className="center-container">
                                            <Spinner/>
                                        </div>
                                    )
                        }
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default connector( Company )
