import * as strings from './strings'

const
    permissionsFlags = { can_manage_cards: [ 'team' ] }

export const allow = [
    '/login',
    '/linkedin',
    '/restore-password',
    '/code-login',
    '/sign-up',
    '/sharing/[A-Za-z0-9-]+/?$',
    '/sharing/:id',
    '/identity',
    '/identity/',
    '/identity/[A-Za-z0-9-]+/?$',
    '/identity/:id',
    '/promo/[A-Za-z0-9-]+/?$',
    '/promo/:id',
    '/found/[A-Za-z0-9-]+/?$',
    '/found/:id',
    '/digital',
    '/digital/[A-Za-z0-9-]+/?$',
    '/digital/:id',
    '/auth/2fa',
    '/auth/sso'
]

export const free = url =>
    allow.reduce(( res, current ) => res || ( new RegExp( current )).test( `/${strings.clean( url )}` ), false )

export const load = ( props, ignoreLoad ) => {
    props.getCompanies()
    props.getUserCards()
    props.getUserContacts()

    if ( !ignoreLoad ) {
        props.getUserTags()
    }
}

export const allowed = ( account, item ) =>
    (
        free( item.path ) ||
        ( account && item && account.permissions[ item.slug ])
    )

export const hide = ( account, item ) => account && item && account.permissions[ item.slug ] === 'hide'

export const prepare = raw => {
    const
        res = {
            id:          raw.id,
            permissions: {
                account:          true,
                company:          false,
                corporateWallets: false,
                wallets:          true,
                sharing:          true,
                team:             false,
                todo:             true
            }
        }

    if ( raw.first_name !== void 0 ) {
        res.name = `${raw.first_name || ''} ${raw.last_name || ''}`.trim()
        res.image = raw.avatar
        ;( !res.name ) && ( res.name = 'Account' )
    } else {
        res.id = raw.company.id
        res.name = raw.company.name
        res.image = raw.company.square_logo

        res.permissions.wallets = 'hide'
        res.permissions.corporateWallets = true
        res.permissions.company = true

        Object.keys( raw.role ).forEach( key => {
            if ( permissionsFlags[ key ]) {
                permissionsFlags[ key ].forEach( sub => res.permissions[ sub ] = raw.role[ key ])
            }
        })
    }

    return res
}
