
import { Checkbox, Skeleton } from 'antd'

import {
    ContactCard,
    Tag,
    Tel,
    WalletsColumnsFilters,
    Image,
    UnifiedSorter,
    Icons
} from 'components'
import { sorter, stub, t, strings } from 'tools'

const withPlaceholder = ( key, handler ) => ( cell, row ) => {
    if ( !cell || ( Array.isArray( cell ) && cell.length < 1 )) {
        return (
            <span className="wallet-empty-placeholder tagged">
                { t( 'global.add' ) } { t( `columns.columns${strings.capitalize( key )}` ) }
            </span>
        )
    }

    return handler( cell, row )
},

      isFullRow = render => ( cell, row ) =>
          (
              row.full
                  ? (
                          row.content && cell
                              ? row.content()
                              : ({
                                      props:    { colSpan: 0 },
                                      children: null
                                  })
                      )
                  : (
                          render( cell, row )
                      )
          ),

      columns = cmp => {
          const
              { columns } = cmp.state,
              widthConfig = sessionStorage.getItem( '_tabee_wallets_width' )
                  ? JSON.parse( sessionStorage.getItem( '_tabee_wallets_width' ))
                  : {},
              res = [ {
                  dataIndex: 'full',
                  width:     48,
                  lockWidth: true,
                  title:     () => (
                      <Checkbox
                          checked = { cmp.allChecked() }
                          disabled = { !cmp.props.wallets }

                          onClick = { cmp.toggleAll }
                      />
                  ),
                  onHeaderCell: () => ({ className: 'leading-cell' }),
                  onCell:       () => ({ className: 'leading-cell' }),
                  render:       isFullRow(( n, wallet ) => (
                      <Checkbox
                          checked = { cmp.props.selected.indexOf( wallet.id ) > -1 }
                          disabled = { !cmp.props.wallets }

                          onChange = { cmp.toggle( wallet.id ) }
                      />
                  ))
              } ]

          if ( columns.type === 'unified' ) {
              res.push({
                  title: () => (
                      <div className="unified-th">
                          <span>{ t( 'columns.columnsName' ) }</span>
                          {
                              cmp.props.wallets && (
                                  <UnifiedSorter
                                      sort = { columns.sort }
                                      onChange = { cmp.setSort }
                                  />
                              )
                          }
                      </div>
                  ),
                  dataIndex: 'fullName',
                  width:     widthConfig.fullName || 272,
                  minWidth:  272,
                  sorter:    sorter.fav(( a, b ) => {
                      const
                          key = cmp.state.columns.sort,
                          check = sorter.empty( key )( a, b )

                      if ( check !== 0 ) { return check }
                      if ( sorter.none( key )( a, b )) { return 0 }

                      return a[ key ].localeCompare( b[ key ])
                  }),
                  render: isFullRow(( fullName, wallet ) => (
                      <ContactCard
                          name = { fullName }
                          position = { wallet.position }
                          file = { wallet.file }
                          photo = { wallet.photo }
                          company = { wallet.companyName }
                          logo = { wallet.companyLogo }
                          favourite = { wallet.is_favourite }
                          todo = { wallet.is_todo }
                      />
                  )),
                  stub: () => <ContactCard skeleton />
              })
          } else {

              if ( columns.photo && columns.logo ) {
                  res.push({
                      title:     t( 'columns.columnsPhoto' ),
                      dataIndex: 'photo',
                      width:     widthConfig.photo || 146,
                      minWidth:  146,
                      render:    isFullRow(( img, row ) => (
                          <div className="figure logo-and-photo">
                              <Image
                                  className = "photo"
                                  fallback = '//tabee.app/files/placeholder_avatar@2x.jpg'
                                  src = { img }
                                  alt = { row.fullName }
                                  width = "64"
                                  height = "64"
                              />
                              <Image
                                  className = "logo"
                                  fallback = {'https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png'}
                                  src = { row.companyLogo }
                                  alt = { row.companyName }
                                  width = "128"
                                  height = "128"
                              />
                              <span className="row-icons">
                                  {
                                      row.is_favourite && (
                                          <span className="fav-icon">
                                              <Icons.Fav />
                                          </span>
                                      )
                                  }
                                  {
                                      row.is_todo && (
                                          <span className="todo-icon">
                                              <Icons.TodoIcon />
                                          </span>
                                      )
                                  }
                              </span>
                          </div>
                      )),
                      stub: () =>
                          <Image
                              className = "only-card"
                              fallback = '//tabee.app/files/placeholder@2x.jpg'
                              src = { null }
                              alt = ""
                              width = "112"
                              height = "64"
                          />
                  })
              } else {

                  ( columns.photo ) && ( res.push({
                      title:     t( 'columns.columnsPhoto' ),
                      dataIndex: 'photo',
                      width:     widthConfig.photo || 100,
                      minWidth:  100,
                      render:    isFullRow(( url, row ) => (
                          <div className="separate-photo">
                              <Image
                                  className = "photo"
                                  fallback = '//tabee.app/files/placeholder_avatar@2x.jpg'
                                  src = { url }
                                  alt = { row.fullName }
                                  width = "48"
                                  height = "64"
                              />
                              <span className="row-icons">
                                  {
                                      row.is_favourite && (
                                          <span className="fav-icon">
                                              <Icons.Fav />
                                          </span>
                                      )
                                  }
                                  {
                                      row.is_todo && (
                                          <span className="todo-icon">
                                              <Icons.TodoIcon />
                                          </span>
                                      )
                                  }
                              </span>
                          </div>
                      )),
                      stub: () =>
                          <Image
                              className = "only-card"
                              fallback = '//tabee.app/files/placeholder_avatar@2x.jpg'
                              src = { null }
                              alt = ""
                              width = "48"
                              height = "64"
                          />
                  }))

                  ;( columns.logo ) && ( res.push({
                      title:     t( 'columns.columnsCompany' ),
                      dataIndex: 'companyLogo',
                      width:     widthConfig.fullName || 100,
                      minWidth:  100,
                      render:    isFullRow(( url, row ) => (
                          <div className="separate-logo">
                              <Image
                                  className = "logo"
                                  fallback = {'https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png'}
                                  src = { url }
                                  alt = { row.companyName }
                                  width = "128"
                                  height = "128"
                              />
                              <span className="row-icons">
                                  {
                                      row.is_favourite && (
                                          <span className="fav-icon">
                                              <Icons.Fav />
                                          </span>
                                      )
                                  }
                                  {
                                      row.is_todo && (
                                          <span className="todo-icon">
                                              <Icons.TodoIcon />
                                          </span>
                                      )
                                  }
                              </span>
                          </div>
                      )),
                      stub: () =>
                          <Image
                              className = "only-card"
                              fallback = {'https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png'}
                              src = { null }
                              alt = ""
                              width = "128"
                              height = "128"
                          />
                  }))
              }

              ( columns.first_name ) && ( res.push({
                  title:     t( 'columns.columnsFirstName' ),
                  dataIndex: 'firstName',
                  width:     widthConfig.firstName || 145,
                  minWidth:  145,
                  sorter:    sorter.fav( sorter.locale( 'firstName' )),
                  render:    withPlaceholder(
                      'firstName',
                      isFullRow( name => <span className="no-wrap-line" title={name}>{name}</span> )
                  ),
                  stub: () => stub.line
              }))

              ;( columns.last_name ) && ( res.push({
                  title:     t( 'columns.columnsLastName' ),
                  dataIndex: 'lastName',
                  width:     widthConfig.lastName || 145,
                  minWidth:  145,
                  sorter:    sorter.fav( sorter.locale( 'lastName' )),
                  render:    withPlaceholder(
                      'lastName',
                      isFullRow( name => <span className="no-wrap-line" title={name}>{name}</span> )
                  ),
                  stub: () => stub.line
              }))

              ;( columns.company ) && ( res.push({
                  title:     t( 'columns.columnsCompany' ),
                  dataIndex: 'companyName',
                  width:     widthConfig.companyName || 145,
                  minWidth:  145,
                  sorter:    sorter.fav( sorter.locale( 'companyName' )),
                  render:    withPlaceholder(
                      'company',
                      isFullRow( name => <span className="no-wrap-line" title={name}>{name}</span> )
                  ),
                  stub: () => stub.line
              }))

              ;( columns.title ) && ( res.push({
                  title:     t( 'columns.columnsPosition' ),
                  dataIndex: 'position',
                  width:     widthConfig.position || 145,
                  minWidth:  145,
                  sorter:    sorter.fav( sorter.locale( 'position' )),
                  render:    withPlaceholder(
                      'position',
                      isFullRow( name => name )
                  ),
                  stub: () => stub.line
              }))
          }

          ( columns.phone ) && ( res.push({
              title:     t( 'columns.columnsPhone' ),
              dataIndex: 'phone',
              width:     widthConfig.phone || 145,
              minWidth:  145,
              sorter:    sorter.fav( sorter.locale( 'phone' )),
              render:    withPlaceholder(
                  'phone',
                  isFullRow( data => {
                      const
                          handler = text => (
                              <div className="no-wrap-line blue" title={text} key={text}>
                                  <Tel number={text} hide />
                              </div>
                          )

                      return Array.isArray( data )
                          ? (
                                  data.length === 0
                                      ? '—'
                                      : data.filter(( d, i ) => data.indexOf( d ) === i ).map( handler )
                              )
                          : handler( data )
                  })
              ),
              stub: () => stub.line
          }))

          ;( columns.email ) && ( res.push({
              title:     t( 'columns.columnsEmail' ),
              dataIndex: 'email',
              width:     widthConfig.email || 145,
              minWidth:  145,
              sorter:    sorter.fav( sorter.locale( 'email' )),
              render:    withPlaceholder(
                  'email',
                  isFullRow( data => {
                      const
                          handler = text => (
                              <div className="no-wrap-line blue" title={text} key={text}>
                                  <a href={`mailto:${text}?subject=${t( 'global.emailSubject' )}&body=${t( 'global.emailBody' )}`}>
                                      { text }
                                  </a>
                              </div>
                          )

                      return Array.isArray( data )
                          ? (
                                  data.length === 0
                                      ? '—'
                                      : data.filter(( d, i ) => data.indexOf( d ) === i ).map( handler )
                              )
                          : handler( data )
                  })
              ),
              stub: () => stub.line
          }))

          ;( columns.tags ) && ( res.push({
              title:     t( 'columns.columnsTags' ),
              dataIndex: 'tags',
              width:     widthConfig.tags || 145,
              minWidth:  145,
              render:    withPlaceholder(
                  'tags',
                  isFullRow( tags => {
                      const
                          max = 2,
                          overflow = tags.length - max,
                          cut = tags.slice( 0, max )

                      return (
                          <div className="cell-tags-list">
                              {
                                  cut.map( tag => (
                                      <Tag
                                          key = { tag.id }
                                          color = { tag.colour }
                                          name = { tag.name }
                                      />
                                  ))
                              }
                              {
                                  overflow > 0 && (
                                      <span className="tags-more">
                                          { t( 'global.tagsOverflow', { overflow }) }
                                      </span>
                                  )
                              }
                          </div>
                      )
                  })
              ),
              stub: () => <Skeleton paragraph={{ rows: 2 }} size="small" active />
          }))

          ;( columns.event ) && ( res.push({
              title:     t( 'columns.columnsEvent' ),
              dataIndex: 'event',
              width:     widthConfig.event || 145,
              minWidth:  145,
              sorter:    sorter.fav( sorter.locale( row => row.event ? row.event.name : '' )),
              render:    withPlaceholder(
                  'event',
                  isFullRow( event => event ? event.name : '' )
              ),
              stub: () => stub.line
          }))

          ;( columns.industry ) && ( res.push({
              title:     t( 'columns.columnsIndustry' ),
              dataIndex: 'industry',
              width:     widthConfig.industry || 145,
              minWidth:  145,
              render:    withPlaceholder(
                  'industry',
                  isFullRow( text => text )
              ),
              stub: () => stub.line
          }))

          return [
              ...res,
              {
                  title: () => (
                      <WalletsColumnsFilters
                          config = { cmp.state.columns }
                          onChange = { cmp.setColumn }
                      />
                  ),
                  lockWidth: 'deny',
                  width:     'auto'
              }
          ]
      }

export default columns
