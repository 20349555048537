

import './public-card-header.scss'

const PublicCardHeader = () => {
    return (
        <header className="public-card-header">
            <picture>
                <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/dark/tabee-logo.png'} media="(prefers-color-scheme: dark)" />
                <source srcSet={'https://server.tabee.mobi/static/tabee/images/mails/tabee-logo.png'} media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
                <img src={'https://server.tabee.mobi/static/tabee/images/mails/tabee-logo.png'} id="tabee-logo" loading="lazy" width="124" height="22" alt="Tabee Logo" className="public-card-logo" />
            </picture>
        </header>
    )
}

export default PublicCardHeader
