import {
    Company,
    Login,
    RestorePassword,
    SignUp,
    Wallets,
    Team,
    Lists,
    Shared,
    ToDo
} from 'pages'

export const global = [
    {
        name:      'Login',
        path:      '/login',
        exact:     true,
        component: Login
    },
    {
        name:      'Restore Password',
        path:      '/restore-password',
        exact:     true,
        component: RestorePassword
    },
    {
        name:      'Sign Up to Tabee',
        path:      '/sign-up',
        exact:     true,
        component: SignUp
    },
    {
        name:      'Shared',
        slug:      'sharing',
        component: Shared,
        exact:     true,
        path:      '/sharing/:id',
        aliases:   [
            '/sharing/:id/:action'
        ]
    }
]

export const settings = [
    {
        name:      'Account Settings',
        slug:      'account',
        component: SignUp,
        exact:     true,
        path:      '/account/'
    },
    {
        name:      'Company Settings',
        slug:      'company',
        component: Company,
        exact:     true,
        path:      '/company/'
    }
]

export const admin = [
    {
        name:      'Wallets',
        slug:      'wallets',
        component: Wallets,
        exact:     true,
        path:      '/wallets/',
        aliases:   [
            '/wallets/:id'
        ]
    },
    {
        name:      'Wallets',
        slug:      'corporateWallets',
        component: Wallets,
        exact:     true,
        path:      '/corporate/wallets',
        aliases:   [
            '/corporate/wallets/:id'
        ]
    },
    {
        name:      'Team',
        slug:      'team',
        component: Team,
        exact:     true,
        path:      '/corporate/team/',
        aliases:   [
            '/corporate/team/:id'
        ]
    },
    {
        name:      'Lists',
        slug:      'sharing',
        component: Lists,
        exact:     true,
        path:      '/sharing',
        aliases:   [
            '/sharing/:id',
            '/sharing/:id/:action'
        ]
    },
    {
        name:      'ToDo',
        slug:      'todo',
        component: ToDo,
        exact:     true,
        path:      '/todo',
        aliases:   [
            '/todo/:id',
            '/todo/:id/:action'
        ]
    }
]

export const site = [
    {
        name:  'about',
        //component: StaticPageView,
        exact: true,
        path:  '/about/',
    },
    {
        name:  'helpNSupport',
        slug:  'help',
        //component: StaticPageView,
        exact: true,
        path:  '/help/',
    },
    {
        name:  'legal',
        slug:  'legal',
        //component: StaticPageView,
        exact: true,
        path:  '/legal/',
    }
]
