

import { Icons, LanguageSwitch } from 'components'
import { t }                     from 'tools'
import config                    from 'config'

import './social-networks.scss'

const SocialNetworks = () => (
    <div className="social-networks">
        <LanguageSwitch />
        <div className="social-networks-label">
            { t( 'global.followUs' ) }
        </div>
        <div className="social-networks-items">
            <a href={config.links.facebook} className="social-network">
                <Icons.Facebook extraClassName="social-network-icon" />
            </a>
            <a href={config.links.twitter} className="social-network">
                <Icons.Twitter extraClassName="social-network-icon" />
            </a>
        </div>
    </div>
)

export default SocialNetworks
