/* IMPORT */
import { connect }                                    from 'react-redux'
import { userActions, authActions, corporateActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    user:      state.user.info,
    companies: state.user.companies,
    current:   state.user.current,
    account:   state.user.account,
    company:   state.companies.current
}),

      allActions = {
          ...userActions,
          ...authActions,
          ...corporateActions
      }

export default connect( mapStateToProps, allActions )
