/* IMPORT */
import { connect }     from 'react-redux'
import { authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    registered: state.auth.registered,
    msg:        state.auth.msg,
    lastTry:    state.auth.lastTry
}),

      allActions = { ...authActions }

export default connect( mapStateToProps, allActions )
