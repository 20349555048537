/* IMPORT */
import { connect }                                  from 'react-redux'
import { sharingActions, userActions, authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    user:      state.user.info,
    companies: state.user.companies,
    current:   state.user.current,
    account:   state.user.account,
    lists:     state.sharing.lists,
    tags:      state.user.tags,
    wallets:   state.wallets.user
}),

      allActions = {
          ...sharingActions,
          ...userActions,
          ...authActions
      }

export default connect( mapStateToProps, allActions )
