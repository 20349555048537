import React      from 'react'
import PropTypes  from 'prop-types'
import classNames from 'classnames'
import { Radio }  from 'antd'

import { Spinner }           from 'components'
import { generate, t, hash } from 'tools'
import config                from 'config'

import './todo-companion.scss'

const
    params = hash.extract()

let
    defShow = 'all'

;( params.filter ) && (
    defShow = params.filter
)

class TodoCompanion extends React.Component {

    static propTypes = {
        search: PropTypes.string,

        todos: PropTypes.array,
        tags:  PropTypes.array,

        getTag:   PropTypes.func,
        onFilter: PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            show:   defShow,
            tagged: params.categories
                ? params.categories.split( ',' )
                : []
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { todos, search } = this.props,
            { show, tagged }  = this.state,
            filters = [],
            hashed = {}

        if (
            todos !== prevProps.todos ||
            show !== prevState.show ||
            tagged !== prevState.tagged ||
            search !== prevProps.search
        ) {
            filters.push({
                type:    'or',
                key:     show,
                handler: config.todo.filters.find( f => f.id === show ).filter
            })

            ;( show !== 'all' )
                ? hashed.filter = show
                : hashed.filter = null

            if ( tagged.length > 0 ) {
                filters.push({
                    type:    'and',
                    handler: item => {
                        if ( tagged.indexOf( 'untagged' ) > -1 && item.tag === null ) {
                            return true
                        } else {
                            return tagged.indexOf( item.tag ) > -1
                        }
                    }
                })
                hashed.categories = tagged.join( ',' )
            }

            ( !!search ) && ( hashed.search = search )
            hash.apply({ ...hashed, sort: hash.extract( 'sort' ) }, true )
        }

        if (
            todos !== prevProps.todos ||
            show !== prevState.show ||
            tagged !== prevState.tagged
        ) {
            this.props.onFilter( filters )
        }

        if ( todos !== prevProps.todos && todos ) {
            this.loadTags()
        }
    }

    loadTags = () => {
        const
            { todos, tags } = this.props,
            loaded = tags.map( tag => tag.id ),
            toLoad = todos.map( task => task.tag ).filter( id => !!id && loaded.indexOf( id ) < 0 )

        toLoad.forEach( id => this.props.getTag( id ))
    }

    hasFilter = item => this.state.tagged.indexOf( item.id ) > -1

    filter = item => () => {
        const
            tagged = [ ...this.state.tagged ]

            ;( tagged.indexOf( item.id ) > -1 )
            ? tagged.splice( tagged.indexOf( item.id ), 1 )
            : tagged.push( item.id )

        this.set.tagged( tagged )
    }

    selected = item => this.state.show === item.id

    select = item => () => this.set.show( item.id )

    count = item => {
        const
            { todos } = this.props

        if ( !todos ) {
            return (
                <span className="todo-filter-count">
                        (<Spinner size="small" />)
                </span>
            )
        }

        return (
            <span className="todo-filter-count">
                    ({ todos.filter( item.filter ).length })
            </span>
        )
    }

    filters = () => {
        return (
            <ul className="todo-filters-list">
                {
                    config.todo.filters.map( item => (
                        <li key={item.id} className="todo-filter">
                            <label htmlFor={item.id}>
                                <Radio
                                    id = { item.id }
                                    className = "small-size"
                                    value = { item.id }
                                    checked = { this.selected( item ) }

                                    onChange = { this.select( item ) }
                                />
                                <span className="todo-filter-title">
                                    { item.title } { this.count( item ) }
                                </span>
                            </label>
                            <span className="todo-filter-action">
                                { t( 'todo.todoAddNew' ) }
                            </span>
                        </li>
                    ))
                }
            </ul>
        )
    }

    categories = () => {
        if ( !this.props.todos ) {
            return (
                <div className="todo-categories">
                    <Spinner />
                </div>
            )
        }

        const
            { todos, tags } = this.props,
            res = todos
                .filter( task => !!task.tag )
                .filter(( t, i, a ) => a.indexOf( t ) === i )
                .map( task => {
                    const
                        loaded = tags && tags.find( tag => tag.id === task.tag )

                    return loaded
                        ? loaded
                        : { id: task.tag }
                })

        return (
            <div className="todo-categories">
                <ul className="todo-tags-list">
                    {
                        [
                            {
                                id:   'untagged',
                                name: t( 'todo.todoUntagged' )
                            },
                            ...res
                        ].map( tag => (
                            tag.name
                                ? (
                                        <li
                                            key = { tag.id }
                                            className = {
                                                classNames(
                                                    'todo-tag',
                                                    this.hasFilter( tag ) && 'tag-selected'
                                                )
                                            }
                                        >
                                            <label className="todo-tag-wrapper" onClick={this.filter( tag )}>
                                                <span
                                                    className = {
                                                        classNames(
                                                            'todo-tag-icon',
                                                            `todo-tag-id-${tag.id}`
                                                        )
                                                    }

                                                    style = {{ backgroundColor: tag.colour }}
                                                />
                                                <span className="todo-tag-title">
                                                    { tag.name }
                                                </span>
                                            </label>
                                            <span className="todo-tag-action">
                                                { t( 'todo.todoAddNew' ) }
                                            </span>
                                        </li>
                                    )
                                : (
                                        <li className="todo-tag" key={tag.id}>
                                            <Spinner size="small" />
                                        </li>
                                    )
                        ))
                    }
                </ul>
                <span className="todo-add-tag">
                    { t( 'todo.todoAddCategory' ) }
                </span>
            </div>
        )
    }

    render () {
        return (
            <div className="todo-companion">
                <div className="todo-filters">
                    { this.filters() }
                </div>
                { this.categories() }
            </div>
        )
    }
}

export default TodoCompanion
