import * as sorter from '../sorter'

import normalizeLink   from './link'
import normalizeMember from './member'

export const member = member => normalizeMember( member )

export const link = link => normalizeLink( link )

export const links = raw => {
    const
        res = []

    raw.forEach( link => {
        res.push( normalizeLink( link ))
    })

    return res.sort( sorter.fav(( a, b ) => b.created - a.created ))
}

export const form = obj => {
    const
        res = []

    Object.keys( obj ).forEach( name => {
        res.push({
            name,
            value: obj[ name ]
        })
    })

    return res
}
