import { request } from 'tools'
import * as api    from './api'

export const getLinks = request.asyncUpdate(
    'storage.wallets.links.get',
    params => api.getLinks( params ),
    ( event, state ) => {
        const
            last   = event.data[ event.data.length - 1 ],
            latest = last ? last.id : null,
            loaded = event.data.length < event.config.params.limit

        return {
            ...state,
            wallets: {
                ...state.wallets,
                user: {
                    ...state.wallets.user,
                    links: event.data,
                    latest,
                    loaded
                }
            }
        }
    }
)

export const appendLinks = request.asyncUpdate(
    'storage.wallets.links.append',
    params => api.getLinks( params ),
    ( event, state ) => {
        const
            last   = event.data[ event.data.length - 1 ],
            latest = last ? last.id : null,
            loaded = event.data.length < event.config.params.limit,
            links = [ ...state.wallets.user.links, ...event.data ]

        return {
            ...state,
            wallets: {
                ...state.wallets,
                user: {
                    ...state.wallets.user,
                    links,
                    latest,
                    loaded
                }
            }
        }
    }
)
