import React                                                        from 'react'
import PropTypes                                                    from 'prop-types'
import { Drawer, Form, Input, Popover, Select, Button, DatePicker } from 'antd'
import moment                                                       from 'moment'

import { Option, Icons, FormSection, SelectedOptionsGroup }                  from 'components'
import { normalize, generate, copy, compare, find, t, i18next, dateLocales } from 'tools'
import config                                                                from 'config'

import './sharing-modal.scss'

const
    { OptionSummary } = Option

class SharingModal extends React.Component {

    static propTypes = {
        disabled: PropTypes.bool,
        open:     PropTypes.bool,

        list:   PropTypes.object,
        tags:   PropTypes.array,
        events: PropTypes.array,

        onSave:  PropTypes.func,
        onClose: PropTypes.func
    }

    form = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            fields: props.list ? this.prepare( props.list ) : {},
            drawer: false,
            sub:    null,
            copied: false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.checkPass()
        i18next.on( 'languageChanged', this.fupdate )
    }

    componentWillUnmount () {
        i18next.off( 'languageChanged', this.fupdate )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { list } = this.props,
            { sub } = this.state

        if ( list !== prevProps.list ) {
            ( list )
                ? this.set.fields( this.prepare( list ), () => {
                    this.checkPass()
                    setTimeout(() => {
                        const
                            title = document.getElementById( 'title' )

                        ;( title ) && ( title.focus())
                    }, 500 )
                })
                : this.set.fields({})
            ;( this.form ) && ( this.form.resetFields())
        }

        ( sub !== prevState.sub && sub ) && ( this.set.drawer( true ))
    }

    fupdate = () => this.forceUpdate()

    checkPass = () => {
        const
            { fields } = this.state

        if ( fields.password ) {
            setTimeout(() => {
                const
                    pass = document.querySelector( '.sharing-options .ant-input-password input[type="password"]' )

                    ;( !!pass ) &&
                        ( document.querySelector( '.sharing-options .ant-input-password .ant-input-suffix .anticon' ).click())
            }, 200 )
        }
    }

    saveLink = () => {
        this.set.drawer( false )

        if ( !this.props.onSave && !this.props.onAdd ) { return }

        const
            { fields } = this.state,
            res = fields.id && fields.id !== 'draft'
                ? {
                        ...this.unprepare( fields ),
                        id: fields.id
                    }
                : this.clear({
                    ...fields,
                    id:           null,
                    is_private:   fields.type === 'private',
                    date_disable: fields.date_disable.unix()
                })

        delete res._date_disable
        delete res.dates

        ;( res.id )
            ? this.props.onSave( res )
            : this.props.onAdd( res )
    }

    addSub = ( sub, value ) => {
        const
            data = {}

        switch ( sub.id ) {
            case 'dates':

                find.dates( value, data )

                this.update( data )
                break
            default:
                this.update({ [ sub.id ]: value })
        }
    }

    clear = data => {
        switch ( data.activity_threshold ) {
            case 'quarter':
                data.last_activity_days = 122
                break
            case 'month':
                data.last_activity_days = 31
                break
            case 'week':
                data.last_activity_days = 7
                break
            default:
                data.last_activity_days = null
        }

        delete data.activity_threshold
        delete data.export_url

        return data
    }

    unprepare = fields => {
        const
            { list } = this.props,
            res = { ...fields }

        res.is_private = fields.type === 'private'
        res.is_fixed = fields.filter === 'fixed'
        res.date_disable = list.date_disable === null ? null : fields.date_disable.unix()
        res.active_reminders = list.active_reminders === null ? null : fields.active_reminders

        this.clear( res )

        delete res.name
        delete res.type
        delete res.filter
        delete res.expire
        delete res._date_disable
        delete res.export_url

        return compare.extract( compare.diff( list, res ), 'b' )
    }

    prepare = raw => {
        const
            data = {
                ...raw,

                export_url: `https://go.tabee.mobi/sharing/${raw.id}`,

                type:   raw.is_private ? 'private' : 'public',
                filter: raw.is_fixed ? 'fixed' : 'dynamic',

                active_reminders: raw.active_reminders || 'none',

                expire:        raw.date_disable === null ? 'year' : 'date',
                _date_disable: raw.date_disable,
                date_disable:  raw.date_disable ? moment.unix( raw.date_disable ) : moment.unix( raw.created ).add( 1, 'year' )
            }

        /* ПИРАМИДА ИФОВ УИИИИИ */
        if ( !raw.last_activity_days ) {
            data.activity_threshold = 'none'
        } else if ( raw.last_activity_days <= 7 ) {
            data.activity_threshold = 'week'
        } else if ( raw.last_activity_days <= 31 ) {
            data.activity_threshold = 'month'
        } else if ( raw.last_activity_days <= 122 ) {
            data.activity_threshold = 'quarter'
        } else {
            data.activity_threshold = 'none'
        }

        data.dates = find.predate( data )

        return data
    }

    update = changed => {
        const
            { fields } = this.state

        if ( changed.type === 'public' ) {
            ( !fields.password ) && ( changed.password = generate.passcode())
            setTimeout(() => {
                const
                    pass = document.querySelector( '.sharing-options .ant-input-password input[type="password"]' )

                    ;( !!pass ) &&
                        ( document.querySelector( '.sharing-options .ant-input-password .ant-input-suffix .anticon' ).click())
            }, 200 )
        }

        if ( changed.type === 'private' ) {
            ( !!fields.password ) && ( changed.password = '' )

            const
                pass = document.querySelector( '.sharing-options .ant-input-password input[type="password"]' )

                ;( !pass ) &&
                    ( document.querySelector( '.sharing-options .ant-input-password .ant-input-suffix .anticon' ).click())
        }

        this.set.fields({
            ...fields,
            ...changed
        })
    }

    close = () => {
        this.set.drawer( false )
        setTimeout(() => this.set.sub( null ), 300 )
    }

    getExpires = data =>
        (
            data.date_disable
                ? t( 'sharing.expires', { fromNow: moment( data.date_disable ).fromNow() })
                : ''
        )

    copyText = data => t(
        'sharing.sharingCopyText',
        {
            link:    data.export_url,
            code:    data.password,
            expires: this.getExpires( data )
        }
    )

    copy = data => e => {
        copy.toClipboard( this.copyText( data ))( e )
        this.set.copied( true )
        setTimeout(() => this.set.copied( false ), 1000 )
    }

    render () {
        const
            { list, open, tags, events, disabled } = this.props,
            { fields, drawer, sub, copied } = this.state,
            noPasscode = fields.type === 'public' && !fields.password

        return (
            <Drawer
                title = ""
                placement = "right"
                closable = { false }
                getContainer = { false }
                push = { false }
                className = "sharing-options"

                visible = { open }
                style = {{ position: 'absolute' }}
                width = { 640 }

                onClose = { this.props.onClose }
            >
                {
                    fields && (
                        <div className="drawer-form">
                            <header className="form-header">
                                <h3>{ t( 'sharing.filterOptions' ) }</h3>
                                <Button onClick={this.props.onClose}>
                                    { t( 'global.cancel' ) }
                                </Button>
                                <Button
                                    type = "primary"
                                    disabled = {
                                        disabled ||
                                        !list ||
                                        noPasscode ||
                                        compare.objects( fields, this.prepare( list ))
                                    }
                                    onClick = { this.saveLink }
                                >
                                    {
                                        fields.id && fields.id !== 'draft'
                                            ? t( 'sharing.updateLink' )
                                            : t( 'sharing.createLink' )
                                    }
                                </Button>
                            </header>
                            <section className="form-content">
                                <Form
                                    { ...config.forms.layout }
                                    ref = { f => this.form = f }
                                    fields = { normalize.form( fields ) }
                                    onValuesChange = { this.update }
                                >

                                    <FormSection>
                                        <Form.Item
                                            name = "title"
                                            label = {
                                                <React.Fragment>
                                                    { t( 'sharing.filterReferenceName' ) }
                                                    <Popover content={t( 'global.optional' )}>
                                                        <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                            <Icons.Info />
                                                        </span>
                                                    </Popover>
                                                </React.Fragment>
                                            }
                                        >
                                            <Input disabled={disabled} />
                                        </Form.Item>
                                    </FormSection>

                                    <FormSection title={t( 'sharing.accessOptions' )}>
                                        <Form.Item
                                            name = "type"
                                            initialValue = "private"
                                            label = {
                                                <React.Fragment>
                                                    { t( 'sharing.linkType' ) }
                                                    <Popover content={ t( 'sharing.linkTypeDescription' ) }>
                                                        <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                            <Icons.Info />
                                                        </span>
                                                    </Popover>
                                                </React.Fragment>
                                            }
                                        >
                                            <Select disabled={disabled}>
                                                <Select.Option key="public" value="public">
                                                    { t( 'sharing.public' ) }
                                                </Select.Option>
                                                <Select.Option key="private" value="private">
                                                    { t( 'sharing.private' ) }
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <input type="text" style={{ display: 'none' }} />

                                        <Form.Item
                                            name = "password"
                                            validateStatus = { noPasscode && 'error' }
                                            label = {
                                                <React.Fragment>
                                                    { t( 'sharing.accessCode' ) }
                                                    {
                                                        fields.type === 'private'
                                                            ? (
                                                                    <Popover content={ t( 'sharing.accessCodeForPrivate' ) }>
                                                                        <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                                            <Icons.Info />
                                                                        </span>
                                                                    </Popover>
                                                                )
                                                            : (
                                                                    <Popover content={ t( 'sharing.accessCodeForPublic' ) }>
                                                                        <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                                            <Icons.Info />
                                                                        </span>
                                                                    </Popover>
                                                                )
                                                    }
                                                </React.Fragment>
                                            }
                                        >
                                            <Input.Password disabled={ disabled || fields.type !== 'public'} />
                                        </Form.Item>

                                        <Form.Item
                                            name = "filter"
                                            initialValue = "dynamic"
                                            label = {
                                                <React.Fragment>
                                                    { t( 'sharing.filterType' ) }
                                                    <Popover content={t( 'sharing.filterTypeDescription' )}>
                                                        <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                            <Icons.Info />
                                                        </span>
                                                    </Popover>
                                                </React.Fragment>
                                            }
                                        >
                                            <Select disabled={disabled || ( fields.links && fields.links.length > 0 )}>
                                                <Select.Option key="dynamic" value="dynamic">
                                                    { t( 'sharing.dynamic' ) }
                                                </Select.Option>
                                                <Select.Option key="fixed" value="fixed">
                                                    { t( 'sharing.fixed' ) }
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name = "expire"
                                            initialValue = "date"
                                            label = { t( 'sharing.expiration' ) }
                                        >
                                            <Select disabled={disabled}>
                                                <Select.Option key="date" value="date">
                                                    { t( 'sharing.onSpecificDate' ) }
                                                </Select.Option>
                                                <Select.Option key="month" value="month">
                                                    { t( 'sharing.afterMonth' ) }
                                                </Select.Option>
                                                <Select.Option key="week" value="week">
                                                    { t( 'sharing.afterWeek' ) }
                                                </Select.Option>
                                                <Select.Option key="year" value="year">
                                                    { t( 'sharing.afterYear' ) }
                                                </Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name = "date_disable"
                                            label = "&nbsp;"
                                        >
                                            <DatePicker
                                                disabled = { disabled }
                                                format = "DD/MM/YYYY"
                                                locale = { dateLocales[ i18next.language ] }
                                            />
                                        </Form.Item>
                                    </FormSection>
                                    {
                                        ( fields.filter === 'dynamic' && !disabled ) && (
                                            <FormSection title={t( 'sharing.dynamicSettings' )}>

                                                <Form.Item
                                                    name = "tags"
                                                    label = { t( 'global.filterByTags' ) }
                                                >
                                                    <OptionSummary
                                                        key = "tags"
                                                        filter = { fields }
                                                        option = {{
                                                            id:        'tags',
                                                            name:      '',
                                                            type:      'tags',
                                                            all:       t( 'global.chooseTags' ),
                                                            counter:   t( 'counters.countersTag' ),
                                                            selection: true,
                                                            sub:       true,
                                                            search:    true,
                                                            items:     tags
                                                                ? (
                                                                        tags
                                                                            .filter( t => !t.is_deleted )
                                                                            .sort(( a, b ) => (
                                                                                (
                                                                                    ( fields.tags || []).indexOf( b.id ) -
                                                                                ( fields.tags || []).indexOf( a.id )
                                                                                ) * 10 -
                                                                            a.name.localeCompare( b.name )
                                                                            ))
                                                                    )
                                                                : []
                                                        }}

                                                        onSelect = { this.set.delayed( 'sub' ) }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name = "events"
                                                    label = { t( 'global.filterByEvents' ) }
                                                >
                                                    <OptionSummary
                                                        key = "events"
                                                        filter = { fields }
                                                        option = {{
                                                            id:        'events',
                                                            name:      '',
                                                            type:      'events',
                                                            all:       t( 'global.chooseEvents' ),
                                                            counter:   t( 'counters.countersEvent' ),
                                                            selection: true,
                                                            sub:       true,
                                                            search:    true,
                                                            items:     events
                                                                ? (
                                                                        events
                                                                            .filter( t => !t.is_deleted )
                                                                            .sort(( a, b ) => (
                                                                                (
                                                                                    fields.events.indexOf( b.id ) -
                                                                                fields.events.indexOf( a.id )
                                                                                ) * 10 +
                                                                            a.name.localeCompare( b.name )
                                                                            ))
                                                                    )
                                                                : []
                                                        }}

                                                        onSelect = { this.set.delayed( 'sub' ) }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name = "dates"
                                                    label = { t( 'global.filterByDates' ) }
                                                >
                                                    <OptionSummary
                                                        key = "dates"
                                                        filter = { fields }
                                                        option = {{
                                                            id:        'dates',
                                                            name:      '',
                                                            def:       1,
                                                            sub:       true,
                                                            secondary: {
                                                                when:      'specific',
                                                                type:      'range',
                                                                id:        'dates-range',
                                                                name:      t( 'global.specifyDates' ),
                                                                simple:    true,
                                                                formatter: date => date
                                                                    ? date.format( config.ui.dates.date )
                                                                    : date,
                                                                parser: raw => raw
                                                                    ? moment( raw, config.ui.dates.date )
                                                                    : raw,
                                                                items: [
                                                                    {
                                                                        id:       1,
                                                                        name:     t( 'global.from' ),
                                                                        nullable: true,
                                                                        def:      moment().subtract( 1, 'month' ).format( config.ui.dates.date )
                                                                    },
                                                                    {
                                                                        id:       2,
                                                                        name:     t( 'global.to' ),
                                                                        nullable: true,
                                                                        def:      moment().format( config.ui.dates.date )
                                                                    }
                                                                ]
                                                            },
                                                            items: [
                                                                {
                                                                    id:   'any',
                                                                    name: t( 'global.anytime' ),
                                                                },
                                                                {
                                                                    id:   'week',
                                                                    name: t( 'global.thisWeek' ),
                                                                },
                                                                {
                                                                    id:   'prevweek',
                                                                    name: t( 'global.previousWeek' )
                                                                },
                                                                {
                                                                    id:   'month',
                                                                    name: t( 'global.thisMonth' ),
                                                                },
                                                                {
                                                                    id:   'prevmonth',
                                                                    name: t( 'global.previousMonth' )
                                                                },
                                                                {
                                                                    id:   'quarter',
                                                                    name: t( 'global.thisQuarter' ),
                                                                },
                                                                {
                                                                    id:   'prevquarter',
                                                                    name: t( 'global.previousQuarter' ),
                                                                },
                                                                {
                                                                    id:   'specific',
                                                                    name: t( 'global.specificDates' ),
                                                                },
                                                            ]
                                                        }}

                                                        onSelect = { this.set.delayed( 'sub' ) }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name = "activity_threshold"
                                                    initialValue = "none"
                                                    label = {
                                                        <React.Fragment>
                                                            { t( 'sharing.lastActivity' ) }
                                                            <Popover content={t( 'sharing.lastActivityDescription' )}>
                                                                <span style={{ marginLeft: 8, lineHeight: 0 }}>
                                                                    <Icons.Info />
                                                                </span>
                                                            </Popover>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Select>
                                                        <Select.Option key="none" value="none">
                                                            { t( 'global.noThreshold' ) }
                                                        </Select.Option>
                                                        <Select.Option key="week" value="week">
                                                            { t( 'global.moreThanWeek' ) }
                                                        </Select.Option>
                                                        <Select.Option key="month" value="month">
                                                            { t( 'global.moreThanMonth' ) }
                                                        </Select.Option>
                                                        <Select.Option key="quarter" value="quarter">
                                                            { t( 'global.moreThanQuarter' ) }
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    name = "active_reminders"
                                                    initialValue = "none"
                                                    label = { t( 'sharing.activeReminders' ) }
                                                >
                                                    <Select>
                                                        <Select.Option key="none" value="none">
                                                            { t( 'sharing.noActiveReminders' ) }
                                                        </Select.Option>
                                                        <Select.Option key="existing" value="existing">
                                                            { t( 'sharing.hasActiveReminders' ) }
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>

                                            </FormSection>
                                        )
                                    }
                                </Form>
                            </section>
                            {
                                !disabled && list && list.export_url && !fields.is_private && (
                                    <footer className="form-footer">
                                        {
                                            fields.is_private
                                                ? <Icons.Linked />
                                                : <Icons.Unlinked />
                                        }
                                        <span className="export-url">
                                            { fields.export_url }
                                        </span>
                                        <Button
                                            type = "primary"
                                            disabled = { copied }

                                            onClick = { this.copy( fields ) }
                                        >
                                            {
                                                copied
                                                    ? t( 'global.copied' )
                                                    : t( 'global.copy' )
                                            }
                                        </Button>
                                    </footer>
                                )
                            }
                        </div>
                    )
                }
                <Drawer
                    title = ""
                    placement = "right"
                    closable = { false }
                    getContainer = { false }
                    className = "wallets-filters sharing-filters-sub"

                    visible = { drawer }
                    style = {{ position: 'absolute' }}
                    width = { 480 }

                    onClose = { this.close }
                >
                    {
                        sub && (
                            <SelectedOptionsGroup
                                option = { sub }
                                filter = { fields[ sub.id ] }

                                onSelect = { this.delayApply }
                                onAccept = { this.addSub }
                                onClose = { this.close }
                            />
                        )
                    }
                </Drawer>
            </Drawer>
        )
    }
}

export default SharingModal
