
import { Skeleton } from 'antd'

import { Wrapper, UserImage } from 'components'

import './admin-header.scss'

const AdminHeader = ({ account, onTogglePanel, ...props }) =>
    Wrapper( 'admin-header' )({
        ...props,
        children: [
            <span key="mobile" className="admin-panel-head-mobile">
                {
                    account
                        ? (
                                <div
                                    className = "control user-panel-control"
                                    onClick = { onTogglePanel }
                                >
                                    <UserImage src={account.image} fullName={account.name} />
                                </div>
                            )
                        : (
                                <Skeleton.Avatar
                                    active
                                    shape = "square"
                                    style={{ width: 48, height: 48, margin: '16px 8px' }}
                                />
                            )
                }
            </span>,
            ...(
                Array.isArray( props.children )
                    ? props.children
                    : [ props.children ]
            )
        ]
    })

export default AdminHeader
