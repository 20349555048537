import React                               from 'react'
import PropTypes                           from 'prop-types'
import { Checkbox, Radio, Dropdown, Menu } from 'antd'
import classNames                          from 'classnames'

import { Option, Icons } from 'components'
import { strings, t }    from 'tools'
import config            from 'config'

import './wallets-columns-filters.scss'

const
    { OptionSection, OptionName } = Option

class WalletsColumnsFilters extends React.Component {

    static propTypes = {
        config:   PropTypes.object,
        onChange: PropTypes.func
    }

    value = ( item, value ) => item.type === 'radio' ? item.value : value
    disabled = ( item, cb ) => (( !item.disabled || !item.disabled( this.props.config )) && cb())
    set = item => e => this.disabled( item, () => this.props.onChange( item.id, this.value( item, e.target.checked )))

    toggle = item => e => {
        e.stopPropagation()
        this.disabled( item, () => this.props.onChange( item.id, this.value( item, !this.props.config[ item.id ])))
    }

    checked = item =>
        (
            item.disabled && item.disabled( this.props.config )
                ? false
                : (
                        item.type !== 'radio'
                            ? !!this.props.config[ item.id ]
                            : this.props.config[ item.id ] === item.value
                    )
        )

    item = item =>
        (
            <div
                className = {
                    classNames( 'option-item', item.weak && item.weak( this.props.config ) && 'option-weak' )
                }

                key = { item.id }
                onClick = { this.toggle( item ) }
            >
                <OptionName>{t( `columns.columns${strings.toCamelCase( item.name )}` )}</OptionName>
                {
                    item.type !== 'radio'
                        ? (
                                <Checkbox
                                    className = "small-size"
                                    value = { item.id }
                                    checked = { this.checked( item ) }
                                    disabled = { item.disabled && item.disabled( this.props.config ) }

                                    onChange={ this.set( item ) }
                                />
                            )
                        : (
                                <Radio
                                    size = "small"
                                    value = { item.id }
                                    checked = { this.checked( item ) }

                                    onChange={ this.set( item ) }
                                />
                            )
                }
            </div>
        )

    group = option =>
        (
            <OptionSection key={option.id} name={option.name}>
                {
                    option.children.items.map( this.item )
                }
            </OptionSection>
        )

    render () {
        const
            filters = (
                <Menu>
                    { config.ui.columnsConfig.map( this.group ) }
                </Menu>
            )

        return (
            <Dropdown
                overlay = { filters }
                trigger = "click"
                overlayClassName = "wallets-columns-dropdown"
            >
                <div className="wallets-columns-filters">
                    <Icons.Settings />
                </div>
            </Dropdown>
        )
    }
}

export default WalletsColumnsFilters
