import { request } from 'tools'
import * as api    from './api'

export const getTag = request.asyncUpdate(
    'storage.tags.get',
    id => api.getTag( id ),
    ( event, state ) => {
        if ( event.status !== 200 ) {
            return { ...state }
        }

        if ( state.tags.find( t => t.id === event.data.id )) {
            return { ...state }
        }

        return {
            ...state,
            tags: [
                ...state.tags,
                event.data
            ]
        }
    }
)
