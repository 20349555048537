import React                   from 'react'
import { Button, Form, Input } from 'antd'
import TabeeAuth               from '@tabee/frontend-auth'

import {
    StaticPage,
    ListItem,
    SiteNavigation,
    AccountContainer,
    AccountFooter
} from 'components'

import { generate, ui, t, strings } from 'tools'

import connector from './connector'
import './restore-password.scss'

class RestorePassword extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            email:   '',
            message: '',
            locked:  false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.props.setMessage( '' )
    }

    componentDidUpdate ( prevProps ) {
        const
            { msg, lastTry } = this.props

        ;( lastTry !== prevProps.lastTry && msg !== prevProps.msg ) && ( this.set.state({
            message: msg,
            locked:  false
        }))

        ui.focusFirstInput()
    }

    sendRequest = () => {
        const
            { email, locked } = this.state

        if ( locked || !email ) { return }

        this.set.locked( true )
        TabeeAuth.resetPass({ email }).then( data => this.props.setResetPassData( data ))
    }

    setEmail = e => {
        this.props.setMessage( '' )
        this.set.email( e.target.value )
    }

    render () {
        const
            { resetSent } = this.props,
            { locked, email, message } = this.state,
            cls = locked ? 'locked' : ''

        return (
            <StaticPage extraClassName={cls}>

                <AccountContainer title={ t( 'restore.restoreTitle' ) }>
                    <Form>

                        <Form.Item>
                            <Input
                                autoFocus
                                className = "dark-theme"
                                placeholder = { t( 'global.email' ) }
                                disabled = { locked }
                                value = { email }

                                onChange = { this.setEmail }
                                onPressEnter = { this.sendRequest }
                            />
                        </Form.Item>

                        {/*
                        <Form.Item>
                            <Input
                                theme       = "dark"
                                placeholder = "Type in characters in the image"
                                disabled    = { locked }
                                value       = { captcha }
                                onChange    = { this.set.captcha }
                                onEnter     = { this.sendRequest }
                            />
                        </Form.Item>
                        */}

                        <Form.Item className="offset">
                            <Button
                                className = "fit-container"
                                type = "primary"
                                disabled = { locked || !email || resetSent }

                                onClick = { this.sendRequest }
                            >
                                { t( 'restore.sendLink' ) }
                            </Button>
                        </Form.Item>

                    </Form>

                    <div style={{ textAlign: 'center' }}>
                        { message && t( `errors.${strings.toKey( message )}` ) }
                        <ListItem link="/login" extraClassName="login-link">
                            { t( 'global.return' ) }
                        </ListItem>
                    </div>

                </AccountContainer>

                <AccountFooter>
                    <SiteNavigation />
                </AccountFooter>

            </StaticPage>
        )
    }
}

export default connector( RestorePassword )
