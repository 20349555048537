
import classNames   from 'classnames'
import moment       from 'moment'
import { Checkbox } from 'antd'

import { sorter, t, hash } from 'tools'

const
    params = hash.extract(),
    sort = params.sort
        ? params.sort.split( ',' )
        : null,

    columns = cmp => ([
        {
            title:        '',
            dataIndex:    '',
            width:        60,
            onHeaderCell: () => ({ className: 'leading-cell' }),
            onCell:       row => ({
                className: 'leading-cell',
                onClick:   cmp.open( row )
            }),
            render: ( n, row ) => {
                if ( row.is_sub ) {
                    return <span className="sub-draw" />
                } else if ( row.is_next ) {
                    return (
                        <Checkbox

                        />
                    )
                } else {
                    return (
                        <span
                            className = {
                                classNames(
                                    'sub-toggle',
                                    ( cmp.state.open.indexOf( row.id ) > -1 ) && 'open',
                                    ( !row.subtasks && !row.sequence ) && 'disabled'
                                )
                            }
                        />
                    )
                }
            }
        },
        {
            title:            t( 'columns.columnsTitle' ),
            dataIndex:        'title',
            width:            360,
            defaultSortOrder: sort && sort[ 0 ] === 'title' ? sort[ 1 ] + 'end' : void 0,
            sorter:           sorter.locale( 'title' ),
            render:           ( title, row ) =>
                (
                    row.is_sub_add
                        ? (
                                <span className="todo-sub-add">{ title }</span>
                            )
                        : (
                                title
                                    ? (
                                            <span className="no-wrap-line" title={title}>
                                                {
                                                    row.priority_type > 0
                                                        ? (
                                                                <span className="todo-priority">
                                                                    { ( new Array( row.priority_type )).fill( '!' ).join( '' ) }
                                                                </span>
                                                            )
                                                        : null
                                                }
                                                { title }
                                            </span>
                                        )
                                    : '—'
                            )
                )
        },
        {
            title:            t( 'columns.columnsDueDate' ),
            dataIndex:        'due_date',
            width:            120,
            defaultSortOrder: sort && sort[ 0 ] === 'due_date' ? sort[ 1 ] + 'end' : void 0,
            sorter:           sorter.unix( 'due_date' ),
            render:           ( date, row ) =>
                (
                    date
                        ? (
                                <span
                                    className = { classNames(
                                        'no-wrap-line',
                                        ( !row.completed_date && moment.unix( date ).isBefore( moment())) && 'outdated'
                                    )}
                                >
                                    { moment.unix( date ).format( 'DD/MM/YYYY' ) }
                                </span>
                            )
                        : (
                                row.completed_date
                                    ? ''
                                    : <span className="todo-set-due">{ t( 'todo.todoSetDueDate' ) }</span>
                            )
                )
        },
        {
            title:     t( 'columns.columnsCollaboration' ),
            dataIndex: '',
            width:     140,
            render:    () => ''
        },
        {
            title:     '',
            dataIndex: 'tag',
            width:     '100%',
            render:    () => ''
        }
    ])

export default columns
