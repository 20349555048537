import React                                from 'react'
import { Skeleton, Button, Dropdown, Menu } from 'antd'

import { Image, Icons } from 'components'
import { sorter, t }    from 'tools'

const columns = cmp => ([
    {
        dataIndex:    '',
        width:        100,
        title:        '',
        onHeaderCell: () => ({ className: 'leading-cell' }),
        onCell:       () => ({ className: 'leading-cell align-right' }),
        render:       ( n, card ) => (
            <Image
                className = "photo"
                fallback = '//tabee.app/files/placeholder_avatar@2x.jpg'
                src = { card && card.owner_user ? card.owner_user.avatar : null }
                alt = { card && card.owner_user ? `${card.first_name} ${card.last_name}`.trim() : '' }
                width = "48"
                height = "64"
            />
        )
    },
    {
        title:     t( 'columns.columnsFirstName' ),
        dataIndex: 'first_name',
        width:     180,
        sorter:    sorter.locale( 'first_name' ),
        stub:      () => <Skeleton.Input active style={{ width: 120 }} />
    },
    {
        title:     t( 'columns.columnsLastName' ),
        dataIndex: 'last_name',
        width:     180,
        sorter:    sorter.locale( 'last_name' ),
        stub:      () => <Skeleton.Input active style={{ width: 120 }} />
    },
    {
        title:     t( 'columns.columnsUsername' ),
        dataIndex: 'username',
        width:     180,
        sorter:    sorter.locale( card => card.owner_user.username || '' ),
        stub:      () => <Skeleton.Input active style={{ width: 120 }} />
    },
    {
        title:     t( 'columns.columnsPosition' ),
        dataIndex: 'position',
        width:     250,
        sorter:    sorter.locale( 'position' ),
        render:    text => <span className="no-wrap-line" title={text}>{ text }</span>,
        stub:      () => <Skeleton.Input active style={{ width: 120 }} />
    },
    {
        title:     t( 'columns.columnsDepartment' ),
        dataIndex: 'department',
        width:     150,
        stub:      () => <Skeleton.Input active style={{ width: 120 }} />
    },
    {
        title:        '',
        dataIndex:    '',
        onHeaderCell: () => ({ className: 'space-cell' }),
        onCell:       () => ({ className: 'space-cell' }),
    },
    {
        title:     '',
        dataIndex: 'actions',
        width:     150,
        onCell:    () => ({ className: 'align-right' }),
        render:    ( n, card ) => (
            <React.Fragment>
                <Button
                    className = "action-btn icon-only no-border"
                    icon = { <Icons.Edit/> }
                    style = {{ marginRight: '1rem' }}

                    onClick = { cmp.props.onEdit( card ) }
                />
                <Dropdown
                    overlay={
                        <Menu style={{ width: 192 }}>
                            <Menu.Item onClick={cmp.props.onEdit( card )}>
                                { t( 'global.edit' ) }
                            </Menu.Item>
                            <Menu.Item onClick={cmp.onFav( card )}>
                                { t( 'global.favourite' ) }
                            </Menu.Item>
                            <Menu.Item onClick={cmp.onRemove( card )}>
                                { t( 'global.remove' ) }
                            </Menu.Item>
                        </Menu>
                    }
                    trigger = "click"
                    getPopupContainer = { node => node.parentNode }
                >
                    <Button className="action-btn icon-only" icon={<Icons.Ellipsis/>} />
                </Dropdown>
            </React.Fragment>
        ),
        stub: () => (
            <React.Fragment>
                <Skeleton.Input active style={{ width: 32, marginRight: 8 }} />
                <Skeleton.Input active style={{ width: 32 }} />
            </React.Fragment>
        )
    },
])

export default columns
