
import classNames from 'classnames'

import { Image } from 'components'

import './user-photo.scss'

const UserPhoto = ({ src, name, size }) => (
    <span className="user-photo">
        <Image
            className = { classNames( 'photo-image', size && `${size}-size` )}
            src = { src }
            alt = { name }
            width = "16"
            height = "16"
        />
    </span>
)

export default UserPhoto
