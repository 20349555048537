import React                    from 'react'
import PropTypes                from 'prop-types'
import { Table, Empty, Button } from 'antd'

import { FloatingButton, AdminContent, EditMemberForm, InviteForm } from 'components'
import { generate, t, i18next }                                     from 'tools'

import columns from './columns'

class TeamContent extends React.Component {

    static propTypes = {
        open:   PropTypes.bool,
        search: PropTypes.bool,
        invite: PropTypes.bool,

        selected: PropTypes.object,
        cards:    PropTypes.array,
        domains:  PropTypes.array,

        onClose:  PropTypes.func,
        onEdit:   PropTypes.func,
        onReset:  PropTypes.func,
        onInvite: PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            order: 'asc',
            sort:  'first_name',
            cards: props.cards || []
        }

        this.set = generate.set( this )
        this.columns = columns( this )

        this.stub = {
            columns: this.columns.map( c => ({ ...c, render: c.stub || c.render })),
            cards:   ( new Array( 20 )).fill( 1 ).map(( o, i ) => ({ id: i }))
        }

        i18next.on( 'languageChanged', this.updateColumns )
    }

    updateColumns = () => this.columns = columns( this )

    componentDidUpdate ( prevProps ) {
        const
            { cards } = this.props

        ;( cards !== prevProps.cards ) && ( this.set.cards( cards ))
    }

    rowProps = r => ({
        onClick: (
            this.props.cards && this.props.onRow
                ? this.props.onRow( r )
                : null
        )
    })

    onFav = r => () => console.log( 'fav', r )
    onRemove = r => () => console.log( 'remove', r )

    content = ordered =>
        (
            <Table
                sticky
                rowKey = "id"
                pagination = { false }
                showSorterTooltip = { false }
                scroll = {{ x: '100%' }}

                dataSource = { ordered }
                columns = {
                    !!this.props.cards && !!this.state.cards
                        ? (
                                this.props.collapsed
                                    ? this.columns.slice( 0, 2 )
                                    : this.columns
                            )
                        : this.stub.columns
                }

                locale = {{
                    emptyIcon: null,
                    emptyText: this.empty()
                }}

                onRow = { this.rowProps }
            />
        )

    empty = () =>
        (
            <Empty image={null} description={ t( 'global.noItemsMatchingFilters' ) }>
                {/*<Button type="primary" onClick={this.props.onReset}>Reset Filters</Button>*/}
            </Empty>
        )

    render () {
        const
            { open, invite, selected, search, domains } = this.props,
            { sort, order, cards } = this.state,
            ordered = this.props.cards && cards
                ? (
                        order === 'desc'
                            ? cards.sort(( a, b ) => a[ sort ] < b[ sort ])
                            : cards.sort(( a, b ) => a[ sort ] > b[ sort ])
                    )
                : this.stub.cards

        return (
            <AdminContent>
                { this.content( ordered ) }
                {
                    ( this.props.cards && search ) && (
                        <div className="admin-footer">
                            <Button onClick={this.props.onReset}>
                                { t( 'global.resetSearch' ) }
                            </Button>
                        </div>
                    )
                }
                <EditMemberForm
                    open = { open }
                    data = { selected }

                    onClose = { this.props.onClose }
                />
                <InviteForm
                    open = { invite }
                    domains = { domains}

                    onClose = { this.props.onClose }
                    onInvite = { this.props.onClose }
                />
                { ( !open && !invite ) && ( <FloatingButton onClick={this.props.onInvite} /> ) }
            </AdminContent>
        )
    }
}

export default TeamContent
